import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { HttpService } from '../services/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { REACTIVE_DRIVEN_DIRECTIVES } from '@angular/forms/src/directives';
import { count } from 'angular-pipes/utils/utils';


@Component({
    selector: 'app-confrence-registration',
    templateUrl: './confrence-registration.component.html',
    styleUrls: ['./confrence-registration.component.css']
})
export class ConfrenceRegistrationComponent implements OnInit {
    public min = new Date();
    public d1 = '';
    public d2 = '';
    public t1 = '' ;
    public t2 = '' ;
    public max = new Date(2018, 12, 25, 20, 30);
    errMsgDate = 'To date should be greater than from date ';
    readonly errMsgRequired = 'This field is required';
    privateCode = '';
    ZIP_REGEX = /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *\d[A-Z]\d)$/;
    US_PHONE_REGEXP = /^\([2-9]\d\d\) [2-9]\d\d\s-\s\d{4}$/;

    NUM_REGEX = /^[0-9]$/;
    isError = false;
    reqInProgress = false;
    onOccurs = '';
    submitBtnTxt = 'Save';
    showCheckBox = false;
    public checkLinkFlag = true; 
    showMonth = false;
    errorMsg = '';
    successMsg = '';
    isBusinessDay = false;
    tmp = [];
    recurrenceweek = '';
    weekConversion = [
        'first',
        'second',
        'Third',
        'fourth',
        'last'
    ];
    weekmonthGroup = [
        {
            'label': '',
            'name': 'day'
        },
        {
            'label': '',
            'name': 'week'
        }
    ];
    editWeekday = [];
    weekdayformat = [];
    weekDays = [
        {
            'id': 1,
            'value': 'Sun',
            'label': 'Sunday',
            'checked': false
        },
        {
            'id': 2,
            'value': 'Mon',
            'label': 'Monday',
            'checked': false
        },
        {
            'id': 3,
            'value': 'Tue',
            'label': 'Tuesday',
            'checked': false
        },
        {
            'id': 4,
            'value': 'Wed',
            'label': 'Wednesday',
            'checked': false
        },
        {
            'id': 5,
            'value': 'Thu',
            'label': 'Thursday',
            'checked': false
        },
        {
            'id': 6,
            'value': 'Fri',
            'label': 'Friday',
            'checked': false
        },
        {
            'id': 7,
            'value': 'Sat',
            'label': 'Saturday' ,
            'checked': false
        }

    ];
    recurenceInfo = {
        OccursOnEvery: '',
        OnBusinessDay: false,
        DayOfTheWeek: '',
        DayOfTheMonth: '',
        MonthtOfYear: '',
        RecurrenceType: ''
    };
    conference = {
        state: '',
        stateID: '',
        conferenceId: 0,
        recurringEvent: false,
        countryId: 1,
        recurenceTypeID: '',
        scheduleCategoryTypeID:0,
        scheduleGroupTypeID: 0,
        scheduleTypeId: '12',
        conferenceName: '',
        street: '',
        countryName: 'United States',
        conferencePhone: '',
        recurrenceInfo: {},
        imageName: '',
        suiteNo: '',
        city: '',
        zipCode: '',
        confStartDate: '',
        confEndDate: '',
        confStartTime: '',
        confEndTime: '',
        confStartZone: '',
        confWebLink: '',
        confDescription: '',
        confPin: '',
        confContactName: '',
        confContactPhone: '',
        privateCode: '',
        occurenceCount: 0
    };
    country = {
        id: 1,
        code: 'US',
        name: 'UNITED STATES'
    };
    timezones = [
        {
            'offset': 'GMT-12:00',
            'name': 'Etc/GMT-12'
        },
        {
            'offset': 'GMT-11:00',
            'name': 'Etc/GMT-11'
        },
        {
            'offset': 'GMT-11:00',
            'name': 'Pacific/Midway'
        },
        {
            'offset': 'GMT-10:00',
            'name': 'America/Adak'
        },
        {
            'offset': 'GMT-09:00',
            'name': 'America/Anchorage'
        },
        {
            'offset': 'GMT-09:00',
            'name': 'Pacific/Gambier'
        },
        {
            'offset': 'GMT-08:00',
            'name': 'America/Dawson_Creek'
        },
        {
            'offset': 'GMT-08:00',
            'name': 'America/Ensenada'
        },
        {
            'offset': 'GMT-08:00',
            'name': 'America/Los_Angeles'
        },
        {
            'offset': 'GMT-07:00',
            'name': 'America/Chihuahua'
        },
        {
            'offset': 'GMT-07:00',
            'name': 'America/Denver'
        },
        {
            'offset': 'GMT-06:00',
            'name': 'America/Belize'
        },
        {
            'offset': 'GMT-06:00',
            'name': 'America/Cancun'
        },
        {
            'offset': 'GMT-06:00',
            'name': 'America/Chicago'
        },
        {
            'offset': 'GMT-06:00',
            'name': 'Chile/EasterIsland'
        },
        {
            'offset': 'GMT-05:00',
            'name': 'America/Bogota'
        },
        {
            'offset': 'GMT-05:00',
            'name': 'America/Havana'
        },
        {
            'offset': 'GMT-05:00',
            'name': 'America/New_York'
        },
        {
            'offset': 'GMT-04:30',
            'name': 'America/Caracas'
        },
        {
            'offset': 'GMT-04:00',
            'name': 'America/Campo_Grande'
        },
        {
            'offset': 'GMT-04:00',
            'name': 'America/Glace_Bay'
        },
        {
            'offset': 'GMT-04:00',
            'name': 'America/Goose_Bay'
        },
        {
            'offset': 'GMT-04:00',
            'name': 'America/Santiago'
        },
        {
            'offset': 'GMT-04:00',
            'name': 'America/La_Paz'
        },
        {
            'offset': 'GMT-03:00',
            'name': 'America/Argentina/Buenos_Aires'
        },
        {
            'offset': 'GMT-03:00',
            'name': 'America/Montevideo'
        },
        {
            'offset': 'GMT-03:00',
            'name': 'America/Araguaina'
        },
        {
            'offset': 'GMT-03:00',
            'name': 'America/Godthab'
        },
        {
            'offset': 'GMT-03:00',
            'name': 'America/Miquelon'
        },
        {
            'offset': 'GMT-03:00',
            'name': 'America/Sao_Paulo'
        },
        {
            'offset': 'GMT-03:30',
            'name': 'America/St_Johns'
        },
        {
            'offset': 'GMT-02:00',
            'name': 'America/Noronha'
        },
        {
            'offset': 'GMT-01:00',
            'name': 'Atlantic/Cape_Verde'
        },
        {
            'offset': 'GMT',
            'name': 'Europe/Belfast'
        },
        {
            'offset': 'GMT',
            'name': 'Africa/Abidjan'
        },
        {
            'offset': 'GMT',
            'name': 'Europe/Dublin'
        },
        {
            'offset': 'GMT',
            'name': 'Europe/Lisbon'
        },
        {
            'offset': 'GMT',
            'name': 'Europe/London'
        },
        {
            'offset': 'UTC',
            'name': 'UTC'
        },
        {
            'offset': 'GMT+01:00',
            'name': 'Africa/Algiers'
        },
        {
            'offset': 'GMT+01:00',
            'name': 'Africa/Windhoek'
        },
        {
            'offset': 'GMT+01:00',
            'name': 'Atlantic/Azores'
        },
        {
            'offset': 'GMT+01:00',
            'name': 'Atlantic/Stanley'
        },
        {
            'offset': 'GMT+01:00',
            'name': 'Europe/Amsterdam'
        },
        {
            'offset': 'GMT+01:00',
            'name': 'Europe/Belgrade'
        },
        {
            'offset': 'GMT+01:00',
            'name': 'Europe/Brussels'
        },
        {
            'offset': 'GMT+02:00',
            'name': 'Africa/Cairo'
        },
        {
            'offset': 'GMT+02:00',
            'name': 'Africa/Blantyre'
        },
        {
            'offset': 'GMT+02:00',
            'name': 'Asia/Beirut'
        },
        {
            'offset': 'GMT+02:00',
            'name': 'Asia/Damascus'
        },
        {
            'offset': 'GMT+02:00',
            'name': 'Asia/Gaza'
        },
        {
            'offset': 'GMT+02:00',
            'name': 'Asia/Jerusalem'
        },
        {
            'offset': 'GMT+03:00',
            'name': 'Africa/Addis_Ababa'
        },
        {
            'offset': 'GMT+03:00',
            'name': 'Asia/Riyadh89'
        },
        {
            'offset': 'GMT+03:00',
            'name': 'Europe/Minsk'
        },
        {
            'offset': 'GMT+03:30',
            'name': 'Asia/Tehran'
        },
        {
            'offset': 'GMT+04:00',
            'name': 'Asia/Dubai'
        },
        {
            'offset': 'GMT+04:00',
            'name': 'Asia/Yerevan'
        },
        {
            'offset': 'GMT+04:00',
            'name': 'Europe/Moscow'
        },
        {
            'offset': 'GMT+04:30',
            'name': 'Asia/Kabul'
        },
        {
            'offset': 'GMT+05:00',
            'name': 'Asia/Tashkent'
        },
        {
            'offset': 'GMT+05:30',
            'name': 'Asia/Kolkata'
        },
        {
            'offset': 'GMT+05:45',
            'name': 'Asia/Katmandu'
        },
        {
            'offset': 'GMT+06:00',
            'name': 'Asia/Dhaka'
        },
        {
            'offset': 'GMT+06:00',
            'name': 'Asia/Yekaterinburg'
        },
        {
            'offset': 'GMT+06:30',
            'name': 'Asia/Rangoon'
        },
        {
            'offset': 'GMT+07:00',
            'name': 'Asia/Bangkok'
        },
        {
            'offset': 'GMT+07:00',
            'name': 'Asia/Novosibirsk'
        },
        {
            'offset': 'GMT+08:00',
            'name': 'Etc/GMT+8'
        },
        {
            'offset': 'GMT+08:00',
            'name': 'Asia/Hong_Kong'
        },
        {
            'offset': 'GMT+08:00',
            'name': 'Asia/Krasnoyarsk'
        },
        {
            'offset': 'GMT+08:00',
            'name': 'Australia/Perth'
        },
        {
            'offset': 'GMT+08:45',
            'name': 'Australia/Eucla'
        },
        {
            'offset': 'GMT+09:00',
            'name': 'Asia/Irkutsk'
        },
        {
            'offset': 'GMT+09:00',
            'name': 'Asia/Seoul'
        },
        {
            'offset': 'GMT+09:00',
            'name': 'Asia/Tokyo'
        },
        {
            'offset': 'GMT+09:30',
            'name': 'Australia/Adelaide'
        },
        {
            'offset': 'GMT+09:30',
            'name': 'Australia/Darwin'
        },
        {
            'offset': 'GMT+09:30',
            'name': 'Pacific/Marquesas'
        },
        {
            'offset': 'GMT+10:00',
            'name': 'Etc/GMT+10'
        },
        {
            'offset': 'GMT+10:00',
            'name': 'Australia/Brisbane'
        },
        {
            'offset': 'GMT+10:00',
            'name': 'Australia/Hobart'
        },
        {
            'offset': 'GMT+10:00',
            'name': 'Asia/Yakutsk'
        },
        {
            'offset': 'GMT+10:30',
            'name': 'Australia/Lord_Howe'
        },
        {
            'offset': 'GMT+11:00',
            'name': 'Asia/Vladivostok'
        },
        {
            'offset': 'GMT+11:30',
            'name': 'Pacific/Norfolk'
        },
        {
            'offset': 'GMT+12:00',
            'name': 'Etc/GMT+12'
        },
        {
            'offset': 'GMT+12:00',
            'name': 'Asia/Anadyr'
        },
        {
            'offset': 'GMT+12:00',
            'name': 'Asia/Magadan'
        },
        {
            'offset': 'GMT+12:00',
            'name': 'Pacific/Auckland'
        },
        {
            'offset': 'GMT+12:45',
            'name': 'Pacific/Chatham'
        },
        {
            'offset': 'GMT+13:00',
            'name': 'Pacific/Tongatapu'
        },
        {
            'offset': 'GMT+14:00',
            'name': 'Pacific/Kiritimati'
        }
    ];
    errors = {

        conferenceName: '',
        scheduleCategoryTypeID: '',
        scheduleTypeId: '',
        scheduleGroupTypeID: '',
        state: '',
        street: '',
        suiteNo: '',
        city: '',
        conferencePhone: '',
        isWeek:'',
        confStartDate: '',
        confEndDate: '',
        confStartTime: '',
        confEndTime: '',
        confStartZone: '',
        confWebLink: '',
        confDescription: '',
        confContactPhone: '',
        confContactName: '',
        privateCode: '',
        zipCode: '',
        confPin: '',
        recurenceTypeID:'',
        occurenceCount: '',

    };
    states: any[];
    scheduleGroups: any[];
    scheduleCategories: any[];
    recurrenceTypes: any[];

    constructor(private route: ActivatedRoute, private router: Router, private httpService: HttpService, private userService: UserService) {
    }

  
   


   checkUrl(){

       if(this.conference.confWebLink.length ===0) {
         this.isError = true;
         this.errors.confWebLink = "Please provide web link";

       } else {

               const url = "http://" + this.conference.confWebLink;

                this.httpService.checkWebLink(url)
                .subscribe((resp: any) => { console.log(resp);
                    this.reqInProgress = false;
                    if (resp.responseCode === 1) {
                    this.errors.confWebLink = (resp.data[0] == true) ? "Url is valid " : "Url is not valid" ;
                     if(resp.data[0] == false) {
                     this.isError =true;
                     this.checkLinkFlag = false; 
                     }else {
			 this.checkLinkFlag = true; 
                     }
                    } 
                }, (errorResponse: HttpErrorResponse) => {
                  
                 
                    this.isError = true;
                    this.errorMsg = errorResponse.error ? errorResponse.error.message
                        : 'Sorry, unable to complete your request, please refresh this page and try again';
                });
        
      

       }
       return this.isError;
       
          
      
   
          
    }

    showgroup(recurrencetype) {
        this.showMonth = false;

        recurrencetype = recurrencetype.replace('sd-', '');
        this.showCheckBox = (recurrencetype == '42') ? true : false;
        if (recurrencetype == '43' || recurrencetype == '44') {
            if (this.conference.confStartDate == '' || this.conference.confStartDate == null) {
                alert('please select conferecne start date ');
                return;
            }
            const confdate = moment(this.conference.confStartDate, 'YYYY/MM/DD');
            const month = confdate.format('M');
            const day = confdate.format('D');
            const year = confdate.format('YYYY');
            const dayofweek = confdate.format('dddd');
            const weekNumber = require('current-month-week-number');
            const weekNumberForGivenDate = weekNumber.weekNumberForGivenDate(year, month, day);

            switch (recurrencetype) {
                case '43':
                    this.weekmonthGroup[0].label = 'on every ' + day;
                    this.weekmonthGroup[1].label = 'on every ' + this.weekConversion[weekNumberForGivenDate - 1] + ' ' + dayofweek;
                    break;
                case '44':
                    this.weekmonthGroup[0].label = 'on every ' + day + ' of ' + confdate.format('MMMM');
                    this.weekmonthGroup[1].label = 'on the ' + this.weekConversion[weekNumberForGivenDate - 1] + ' ' + dayofweek + ' ' + confdate.format('MMMM');
            }

            this.showMonth = true;
        }
    }

    calculate(id, status) {
        this.isBusinessDay = false;
        const indexkey = parseInt(id, 10) - 1;

        if (status === true) {
            this.tmp.push(id);

            this.weekdayformat.push(this.weekDays[indexkey].value);
            this.errors.isWeek ='';
        }
        else {
            const index = this.tmp.indexOf(id);
            const indexday = this.weekdayformat.indexOf(this.weekDays[indexkey].value);
           // alert(indexday);
            if (index > -1) {
                this.tmp.splice(index, 1);
            }
            if (indexday > -1) {
                this.weekdayformat.splice(indexday, 1);
            }
        }

        var isExist = '';
        for (let i = 0; i < this.tmp.length; i++) {

            if (this.tmp[i] >= 2 && this.tmp[i] <= 6) {
                this.isBusinessDay = true;
                isExist = '1';
            } else {
                this.isBusinessDay = (isExist == '1') ? true : false;

            }
        }

    }

    onChange(categoryId, flag) {
        flag = parseInt(flag, 10);

        categoryId = (flag <= 2) ? parseInt(categoryId, 10) : categoryId;
        switch (flag) {
            case 1:
                (categoryId > 0) ? this.errors.scheduleCategoryTypeID = '' : this.errors.scheduleCategoryTypeID = this.errMsgRequired;
                break;
            case 2:
                (categoryId > 0) ? this.errors.state = '' : this.errors.state = this.errMsgRequired;
                break;
            case 3:
                (categoryId !== '') ? this.errors.confStartZone = '' : this.errors.confStartZone = this.errMsgRequired;
                break;
           
        }

    }


    ngOnInit() {



        this.route.params
            .subscribe(params => {
                this.conference.conferenceId = params['id'];
            });
        if (this.conference.conferenceId > 0) {
            this.httpService.getEventDetails(this.conference.conferenceId).subscribe((resp: any) => {
                if (resp.responseCode === 1) {
                    this.constructEditObj(resp.data);
                }
            });
        } 


        this.httpService.getStates(this.country.id).subscribe((resp: any) => {
            if (resp.responseCode === 1) {
                this.states = resp.data;
            }
        });
        
        this.httpService.getScheduleGroups().subscribe((resp: any) => {
            if (resp.responseCode === 1) {
                this.scheduleGroups = resp.data;
            }
        });
        this.httpService.getScheduleCategories().subscribe((resp: any) => {
            if (resp.responseCode === 1) {
                this.scheduleCategories = resp.data;
            }
        });
        this.httpService.getRecurrenceTypes().subscribe((resp: any) => {
            if (resp.responseCode === 1) {
                this.recurrenceTypes = resp.data;
            }
        });
    }

    showEventForm() {
        this.router.navigate(['/event']);
    }
    constructRecurrence() {
        const confdate = moment(this.conference.confStartDate, 'YYYY/MM/DD');
         const month = confdate.format('M');
        const day = confdate.format('D');
        const year = confdate.format('YYYY');
        const dayofweek = confdate.format('ddd');
        const weekNumberone = require('current-month-week-number');
        for (let i = 0; i < this.weekDays.length; i++) {
            if (confdate.format('dddd') == this.weekDays[i].label) {
                this.recurenceInfo.OnBusinessDay = (this.weekDays[i].id >= 2 && this.weekDays[i].id <= 6) ? true : false;
            }
        }


        const weekNumberForGivenDate = weekNumberone.weekNumberForGivenDate(year, month, day);
        const recurrenceno = parseInt(this.conference.recurenceTypeID, 10);
        this.recurenceInfo.RecurrenceType = this.conference.recurenceTypeID.toString();
        this.onOccurs = this.onOccurs.toString();

        switch (recurrenceno) {
            case 41:
                this.recurenceInfo.OccursOnEvery = this.onOccurs;
                this.recurenceInfo.OnBusinessDay = false;
                this.recurenceInfo.DayOfTheWeek = '';
                this.recurenceInfo.DayOfTheMonth = '';
                this.recurenceInfo.MonthtOfYear = '';

                break;
            case 42:
                this.recurenceInfo.OccursOnEvery = this.onOccurs;
                this.recurenceInfo.OnBusinessDay = this.isBusinessDay;
                this.recurenceInfo.DayOfTheWeek = this.weekdayformat.join(',');
                
                this.recurenceInfo.DayOfTheMonth = '';
                this.recurenceInfo.MonthtOfYear = '';
                break;
            case 43:
                this.recurenceInfo.OccursOnEvery = '1';
                if (this.recurrenceweek == 'day') {
                    this.recurenceInfo.DayOfTheWeek = '';
                    this.recurenceInfo.DayOfTheMonth = day;
                } else {
                    this.recurenceInfo.DayOfTheMonth = confdate.format('ddd');
                    this.recurenceInfo.DayOfTheWeek = this.weekConversion[weekNumberForGivenDate - 1];
                }

                this.recurenceInfo.MonthtOfYear = '';
                break;

            case 44:
                this.recurenceInfo.OccursOnEvery = '1';
              //  this.recurrenceweek == 'week';
                if (this.recurrenceweek == 'day') {
                    this.recurenceInfo.DayOfTheWeek = "";

                    this.recurenceInfo.DayOfTheMonth = day;

                } else {
                    this.recurenceInfo.DayOfTheMonth = confdate.format('ddd');
                    this.recurenceInfo.DayOfTheWeek = this.weekConversion[weekNumberForGivenDate - 1];
                }
                this.recurenceInfo.MonthtOfYear = month;
                break;

        }
        


    }
    constructEditObj(editObj) {
        this.conference.scheduleCategoryTypeID = editObj[0].scheduleCategoryTypeID;
        this.conference.scheduleGroupTypeID = editObj[0].scheduleGroupTypeID;
        this.conference.conferenceName = editObj[0].scheduleName;
        this.conference.street = editObj[0].street;
        this.conference.conferenceName = editObj[0].conferenceName;
        this.conference.suiteNo = editObj[0].suiteNo;
        this.conference.city = editObj[0].city;
        this.conference.stateID = editObj[0].stateCodeID;
        this.conference.zipCode = editObj[0].zipCode;
        this.conference.conferencePhone = editObj[0].conferencePhone
        this.conference.confWebLink = editObj[0].confWebLink;
        this.conference.confDescription = editObj[0].confDescription;
        this.conference.confStartZone = editObj[0].confStartZone;
        this.conference.stateID = editObj[0].stateID;
        this.conference.confContactName = editObj[0].contactName;
        this.conference.confContactPhone = editObj[0].contactPhone;
        this.conference.confPin = editObj[0].conferencePIN;
        this.conference.privateCode =  editObj[0].privateCode;
        this.conference.occurenceCount = editObj[0].noOfOccurance;


        const startdateSplitter = editObj[0].confStartDate.split("T");
        const enddateSplitter = editObj[0].confEndDate.split("T");


        this.conference.confStartDate = startdateSplitter[0];
        this.conference.recurringEvent = editObj[0].recurringEvent;
        if(this.conference.recurringEvent === true){
           // alert(editObj[0].recurrenceInfo.occursOnEvery);
            this.onOccurs = (editObj[0].recurrenceInfo ==null ) ? 1 : editObj[0].recurrenceInfo.occursOnEvery;
            this.conference.recurenceTypeID = 
              (editObj[0].recurrenceInfo.recurrenceType == null) ? 41 : editObj[0].recurrenceInfo.recurrenceType;
            this.showgroup('sd-'+ this.conference.recurenceTypeID);
            this.recurenceInfo.DayOfTheWeek = editObj[0].recurrenceInfo.dayOfTheWeek ;
            const editWeekSplitter = editObj[0].recurrenceInfo.dayOfTheWeek.split(",");
            if (editWeekSplitter.length > 0 && editWeekSplitter[0] !== '' && this.conference.recurenceTypeID =='42') {
                for(let  i= 0 ;i < editWeekSplitter.length;i++){
                  var indexid = this.findIndexInData(this.weekDays,'value',editWeekSplitter[i]);
                  this.weekDays[indexid].checked =true;
                  this.calculate((indexid+1),true);
                }
            }
            if(this.conference.recurenceTypeID == "43" || this.conference.recurenceTypeID =="44")  {
                var matches = editObj[0].recurrenceInfo.dayOfTheMonth.match(/\d+/g);
                if (matches != null) {
                this.recurrenceweek = 'day';
                } else {
                    this.recurrenceweek = 'week';
                }

            }
           }
 
        this.conference.confStartTime = editObj[0].confStartTime;
        this.conference.confEndTime = editObj[0].confEndTime;
        this.conference.confEndDate = enddateSplitter[0];
       this.constructRecurrence();


    }
    findIndexInData(data, property, value) {
        for(var i = 0, l = data.length ; i < l ; i++) {
          if(data[i][property] === value) {
            return i;
          }
        }
        return -1;
      }

    submitConference() {
        this.errorMsg = '';
        if (!this.doValidation() && !this.reqInProgress) {
           if(this.conference.confEndDate == '') {
               this.conference.confEndDate = this.conference.confStartDate;
           }
        
            const sId = parseInt(this.conference.stateID, 10);

            let sName = '';
            this.states.forEach(function (state) {
                if (state.stateID === sId) {
                    sName = state.stateName;
                }
            });
             this.d1 = this.conference.confStartDate ;
             this.d2 = this.conference.confEndDate ;
             this.t1 = this.conference.confStartTime ;
             this.t2 = this.conference.confEndTime ;
            this.conference.state = sName;
            this.conference.scheduleGroupTypeID = this.conference.scheduleGroupTypeID;
            if (this.conference.recurringEvent === true) {


               if(this.conference.recurenceTypeID!='') {
                this.constructRecurrence();
                this.conference.recurrenceInfo = this.recurenceInfo;
               } else {
                  this.isError =true;
                  this.errors.recurenceTypeID ="Please choose any one type of recurrence" ;

               }


            } else  {
                this.conference.recurrenceInfo = {};

            }
        this.conference.confStartDate = moment(this.conference.confStartDate).format('YYYY/MM/DD');
        this.conference.confEndDate = moment(this.conference.confEndDate).format('YYYY/MM/DD');
        this.conference.confStartTime = moment(this.conference.confStartTime).format('hh:mm');
        this.conference.confEndTime = moment(this.conference.confEndTime).format('hh:mm');
        this.reqInProgress = true;
        this.submitBtnTxt = 'Please wait...';
         this.conference.confEndDate = (this.conference.recurringEvent === true) ? this.conference.confStartDate :this.conference.confEndDate ;
         
       

         this.httpService.createConference(this.conference).subscribe((resp: any) => {
                this.reqInProgress = false;
                this.submitBtnTxt = 'Save';
                if (resp.responseCode === 1) {
                    setTimeout(() => {
                        this.successMsg = resp.message;
                        window.scrollTo(0, 0);
                        setTimeout(() => {
                        this.router.navigate([`/invite/conference/${resp.data[0].conferenceID}`]);
                        }, 2000);
                    }, 2000);

                } else {
                    this.isError = true;
                    this.errorMsg = resp.message;
                }
            }, (errorResponse: HttpErrorResponse) => {
                this.reqInProgress = false;
                this.submitBtnTxt = 'Save';
                this.isError = true; 
                this.errorMsg = errorResponse.error ? errorResponse.error.message
                    : 'Sorry, unable to complete your request, please refresh this page and try again';
                    window.scrollTo(0, 0);
            });
          

        }
         this.conference.confStartDate = this.d1;
         this.conference.confEndDate = this.d2 ;
         this.conference.confStartTime = this.t1 ;
         this.conference.confEndTime = this.t2 ;

       
    }

    clearForm(event: KeyboardEvent, field: string) {
        if (field === 'en') {
            this.errors.conferenceName = '';
        } else if (field === 'st') {
            this.errors.street = '';
        } else if (field === 'sn') {
            this.errors.suiteNo = ''; 
        } else if (field === 'ci') {
            this.errors.city = '';
        } else if (field === 'zi') {
            this.errors.zipCode = '';
        } else if (field === 'ewl') {
            this.errors.confWebLink = '';
        } else if (field === 'ed') {
            this.errors.confDescription = '';
        } else if (field === 'pv') {
            this.errors.privateCode = '';
        } else if (field === 'cph') {
            this.errors.confContactPhone = '';
        } else if (field === 'cp') {
            this.errors.confPin = '';
        } else if (field === 'contact') {
            this.errors.confContactName = '';
        }

    }
    doValidation() {
        this.isError = false;
        const mErrorMsg = '';
        this.errors.conferencePhone = '';
        this.errors.confStartDate = '' ;
        this.errors.confStartTime = '' ;
        this.errors.confEndTime = '';
        this.errors.occurenceCount = '';
        if (this.conference.scheduleCategoryTypeID === 0) {
            this.isError = true;
            this.errors.scheduleCategoryTypeID = this.errMsgRequired;
        }
        if (this.conference.scheduleGroupTypeID === 0) {
            this.isError = true;
            this.errors.scheduleGroupTypeID = this.errMsgRequired;
        } else {
            this.errors.scheduleGroupTypeID = '';

        }
        if (this.conference.conferenceName.trim().length === 0) {
            this.isError = true;
            this.errors.conferenceName = this.errMsgRequired;
        }
        if (this.conference.street.trim().length === 0) {
            this.isError = true;
            this.errors.street = this.errMsgRequired;
        }
      
        if (this.conference.city.trim().length === 0) {
            this.isError = true;
            this.errors.city = this.errMsgRequired;
        }
        if (this.conference.stateID === '') {
            this.isError = true;
            this.errors.state = this.errMsgRequired;
        }
        if (this.conference.zipCode.trim().length === 0) {
            this.isError = true;
            this.errors.zipCode = this.errMsgRequired;
        } else if (!this.ZIP_REGEX.test(this.conference.zipCode.trim())) {
            this.isError = true;
            this.errors.zipCode = 'Should contain only valid us zipcode';
        }
        if (this.conference.conferencePhone.trim().length === 0) {
            this.isError = true;
            this.errors.conferencePhone = this.errMsgRequired;
        }

        if (this.conference.confPin.trim().length === 0) {
            this.isError = true;
            this.errors.confPin = this.errMsgRequired;
        }
        if (this.conference.confStartDate.length === 0) {
            this.isError = true;
            this.errors.confStartDate = this.errMsgRequired;
        } else {
           this.d1 = this.conference.confStartDate;

          }


        if (this.conference.confStartTime.length === 0) {
            this.isError = true;
            this.errors.confStartTime = this.errMsgRequired;
        }  else {
           this.t1 = this.conference.confStartTime;

         }    

      if (this.conference.confEndTime.length === 0) {
            this.isError = true;
            this.errors.confEndTime = this.errMsgRequired;
        } else { 
             this.t2 = this.conference.confEndTime;
        }
        if (this.conference.scheduleGroupTypeID.toString()  == '21' ) {
            if(this.conference.privateCode == '') {
                 this.isError = true;
                  this.errors.privateCode = this.errMsgRequired;

              } 
        } 
      
        if (this.conference.recurringEvent === true) {

             if(this.conference.recurenceTypeID =='') {
               
                  this.isError =true;
                  this.errors.recurenceTypeID ="Please choose any one type of recurrence" ;
               } else {
                   this.errors.recurenceTypeID ='';

              }
           if (this.conference.confEndTime.length === 0) {
            this.isError = true;
            this.errors.confEndTime = this.errMsgRequired;
           }
         if (this.conference.occurenceCount ==  0 ) {
            this.isError = true;
            this.errors.occurenceCount = this.errMsgRequired;
        }

       } else {

          
        if (this.conference.confEndDate.length === 0) {
            this.isError = true;
            this.errors.confEndDate = this.errMsgRequired;
        } else {
           this.d2 = this.conference.confEndDate;

          }

         }
        if(this.conference.recurenceTypeID =='42' && this.weekdayformat.length ===0) {
            this.isError = true;
            this.errors.isWeek = "Please choose any of the day";
        }
        else {
            this.errors.isWeek = "";

        }

        

        if (this.conference.confWebLink.trim().length > 0 && this.checkLinkFlag === false) {
            this.isError = true;
            this.errors.confWebLink = "Please provide valid url";
        }
        if (this.conference.confStartZone.length === 0) {
            this.isError = true;
            this.errors.confStartZone = this.errMsgRequired;
        } 
        

        if (this.conference.confDescription.trim().length === 0) {
            this.isError = true;
            this.errors.confDescription = this.errMsgRequired;
        }
        if (this.conference.confContactName.trim().length === 0) {
            this.isError = true;
            this.errors.confContactName = this.errMsgRequired;
        }
        if (this.conference.confContactPhone.trim().length === 0) {
            this.isError = true;
            this.errors.confContactPhone = this.errMsgRequired;
        }
         this.conference.confStartDate = this.d1;
         this.conference.confEndDate = this.d2;
         this.conference.confStartTime= this.t1;
           this.conference.confEndTime = this.t2;

        return this.isError;


    }

}
