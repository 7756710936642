import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {UserService} from './user.service';
import {Subscribable} from 'rxjs';




@Injectable({
    providedIn: 'root'
})
export class HttpService {

   // readonly baseURL = 'http://eventapi.princesuperstores.com/api';
   readonly baseURL = 'http://50.62.59.235/api';
   urlString:any;

    constructor(private httpClient: HttpClient, private userService: UserService) {
    }

    login(email: string, password: string) {
        const data = {Username: email, Password: password};
        const d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return this.httpClient.post(`${this.baseURL}/authuser`, d, {headers: h});
    }

    signUp(user: any) {
        const data = {
            CompanyName: user.companyName,
            ContactNumber: user.phone,
            EmailLogin: user.email,
            FirstName: user.firstName,
            LastName: user.lastName,
            Password: user.password,
            UserStatus: 0
        };
        const d = JSON.stringify(data);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return this.httpClient.post(`${this.baseURL}/user`, d, {headers: h});
    }

    Invite(payload: any) {
        const d = JSON.stringify(payload);
        const h = new HttpHeaders({'Content-Type': 'application/json'});
        return this.httpClient.post(`${this.baseURL}/sendinvites`, d, {headers: h});
    }

    changePassword(fields: any) {
        const data = {
            UserID: fields.userId,
            OldPassword: fields.oldPassword,
            NewPassword: fields.newPassword,
            ConfirmNewPassword: fields.confirmPassword
        };
        const d = JSON.stringify(data);
        const h = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.userService.getToken()}`
        });
        return this.httpClient.post(`${this.baseURL}/updatepwd`, d, {headers: h});
    }
 
    getStates(countryId): any {
        return this.httpClient.get(`${this.baseURL}/states/${countryId}`);
    }
    getPrivateCode(): any {
        const h = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.userService.getToken()}`
        });
        return this.httpClient.get(`${this.baseURL}/getcode/`, {headers: h});


    }
    getEventDetails(scheduleId): any {
        return this.httpClient.get(`${this.baseURL}/schedueldetails/${scheduleId}`);
    }
    getCategoryDetails(categoryId): any {
        return this.httpClient.get(`${this.baseURL}/schedulesbycategory/${categoryId}`);
  
    }
    getScheduleGroups(): any {
        return this.httpClient.get(`${this.baseURL}/schedulegroups`);
    }
    getScheduleTypes(): any {
        return this.httpClient.get(`${this.baseURL}/scheduletypes`);
    }

    getScheduleCategories(): any {
        return this.httpClient.get(`${this.baseURL}/schedulecategories`);
    }

    getRecurrenceTypes(): any {
        return this.httpClient.get(`${this.baseURL}/recurrencetypes`);
    }

    getEventsListHomePg(): any {
        return this.httpClient.get(`${this.baseURL}/listschedules`);
    }
    getSuggestEvents(scheduleId) {
        return this.httpClient.get(`${this.baseURL}/suggestions/${scheduleId}`);

    }
    createConference(conference: any) {
       // const conferenceID = 0;
        const data = {
            UserID: this.userService.getLoginUserId(),
            ConferenceID: conference.conferenceId,
            ScheduleCategoryTypeID : conference.scheduleCategoryTypeID,
            ScheduleGroupTypeID : conference.scheduleGroupTypeID ,
            ConferenceName : conference.conferenceName,
            Street : conference.street ,
            SuiteNo : conference.suiteNo ,
            City : conference.city ,
            State : conference.state,
            ZipCode: conference.zipCode ,
            CountryID : conference.countryId ,
            StateID : conference.stateID ,
            ConferencePhone: conference.conferencePhone ,
            CountryName: conference.countryName ,
            RecurringEvent: conference.recurringEvent,
            RecurrenceInfo: conference.recurrenceInfo ,
            ConfStartZone : conference.confStartZone ,
            ConfEndZone : conference.confEndZone ,
            ConfStartDate : conference.confStartDate ,
            ConfStartTime : conference.confStartTime ,
            ConfEndDate : conference.confEndDate ,
            ConfEndTime : conference.confEndTime ,
            ConfWebLink : conference.confWebLink ,
            PrivateCode : conference.privateCode ,
            ConfDescription : conference.confDescription ,
            ImageName : conference.imageName ,
            ContactName: conference.confContactName,
            ContactPhone : conference.confContactPhone,
            ConferencePIN : conference.confPin,
            Base64Representation: '',
            NoOfOccurance : conference.occurenceCount

        };
        const d = JSON.stringify(data);
        console.log(d);
        const h = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.userService.getToken()}`
        });
        return this.httpClient.post(`${this.baseURL}/createconf`, d, {headers: h});
   }


   googleLink(){
       //

       this.urlString = "https://ipinfo.io/json";
       const h = new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    });
    return this.httpClient.get(`${this.urlString}`, {headers: h});

  //  return this.httpClient.get(this.urlString);
   }
   searchDashboardEvent(search:any){

    const data = {
        ScheduleType: search.scheduleTypeId ,
        ListType : search.listType ,
        ScheduleName: search.scheduleName,
        FromDate:search.fromDate ,
        ToDate :search.toDate,
        UserID:this.userService.getLoginUserId()
    };
    const d = JSON.stringify(data);
    const h = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.userService.getToken()}`
    });
    return this.httpClient.post(`${this.baseURL}/searchschedules`, d, {headers: h,observe: 'response'});


   }
   checkWebLink(url) {
   const data = {
     URL:url

   }
     const d = JSON.stringify(data);
    const h = new HttpHeaders({
        'Content-Type': 'application/json',

    });
    return this.httpClient.post(`${this.baseURL}/checkurl`, d, {headers: h});


   }
   searchEvent(search:any){

    const data = {
        ScheduleType: search.scheduleTypeId ,
        ListType : search.listType ,
        ScheduleName: search.scheduleName,
        FromDate:search.fromDate ,
        ToDate :search.toDate,
        UserID:search.userId,   
        CountryID: search.countryId ,
        TicketPrice: 0 ,
        CategoryTypeID: search.scheduleCategoryTypeID,
        Location: search.location

    };
    const d = JSON.stringify(data);
    const h = new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': `Bearer ${this.userService.getToken()}`
    });
    return this.httpClient.post(`${this.baseURL}/filterschedules`, d, {headers: h, observe: 'response'});

   }

    createEvent(event: any) {
        const data = {
            UserID: event.userId,
            EventID: event.eventId,
            ScheduleCategoryTypeID: event.scheduleCategoryTypeID,
            ScheduleGroupTypeID: event.scheduleGroupTypeID,
            EventName: event.eventName,
            Street: event.street,
            SuiteNo: event.suiteNo,
            City: event.city,
            State: event.state,
            ZipCode: event.zipCode,
            CountryID: event.countryId,
            StateID: event.stateID,
            CountryName: event.countryName,
            MultiDayEvent: event.multiDate,
            EventDays: event.EventDays,
            EventStartDate: event.eventStartDate,
            EventStartTime: event.eventStartTime,
            EventEndDate: event.eventEndDate,
            EventEndTime: event.eventEndTime,
            TicketStartDate: event.ticketStartDate,
            TicketStartTime: event.ticketStartTime,
            TicketEndDate: event.ticketEndDate,
            TicketEndTime: event.ticketEndTime,
            TicketPriceRange: event.ticketPriceRange,
            EventWebLink: event.eventWebLink,
            PrivateCode: event.privateCode,
            EventDescription: event.eventDescription,
            FullDayEvent: false,
            ImageName: '',
            Base64Representation: ''
        };

        const d = JSON.stringify(data);
        const h = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.userService.getToken()}`
        });
        return this.httpClient.post(`${this.baseURL}/newevent`, d, {headers: h});
    }

    getUserDashboard(): any {
        const userId = this.userService.getLoginUserId();
        const token = this.userService.getToken();
        const h = new HttpHeaders({'Authorization': `Bearer ${token}`});
        return this.httpClient.get(`${this.baseURL}/userschedules/${userId}`, {headers: h});
    }

    getInviteesList(eventId): any {
        const token = this.userService.getToken();
        const h = new HttpHeaders({'Authorization': `Bearer ${token}`});
        return this.httpClient.get(`${this.baseURL}/listinvitee/${eventId}`, {headers: h});
    }

    deleteEvent(eventObj: any) {
        const payload = {
            ScheduleID: eventObj.scheduleID,
            UserID: this.userService.getLoginUserId(),
            Reason: eventObj.deleteReason
        };
        const d = JSON.stringify(payload);
        const h = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.userService.getToken()}`
        });
        return this.httpClient.post(`${this.baseURL}/deleteschedule`, d, {headers: h});

    }

  forgot(email){
        const payload = {
            UserName: email
        };
        const d = JSON.stringify(payload);
        const h = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return this.httpClient.post(`${this.baseURL}/getcredentials`, d, {headers: h});

    }

    likeEvent(payload: any) {
        const data = {
            UserID: this.userService.getLoginUserId(),
            ScheduleID: payload
        };
        const d = JSON.stringify(data);
        const h = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.userService.getToken()}`
        });
        return this.httpClient.post(`${this.baseURL}/likeschedule`, d, {headers: h});
    }
    getEventByPrivatecode(privateCode){
       
        return this.httpClient.get(`${this.baseURL}/joinevent/${privateCode}`);

    }

     removeInvite(payload: any) {
        const data = {
            InvitationID: payload.invitationId,
            ScheduleID: payload.scheduleId,
            UserID: this.userService.getLoginUserId(),
            CancelRemarks: 'wrongly sent'
        };
        const d = JSON.stringify(data);
        const h = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.userService.getToken()}`
        });
        return this.httpClient.post(`${this.baseURL}/deleteinvite`, d, {headers: h});


     }

    joinEvent(payload: any) {
        const data = {
            InvitationID: 0,
            ScheduleID: payload.scheduleId,
            UserEMail: this.userService.getLoginUserEmail(),
            PrivateCode: payload.privateCode
        };
        const d = JSON.stringify(data);
        const h = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.userService.getToken()}`
        });
        return this.httpClient.post(`${this.baseURL}/joinschedule`, d, {headers: h});
    }

    sendInvities(payload: any) {
        const data = {
            UserID: this.userService.getLoginUserId(),
            ScheduleID: payload.ScheduleID,
            EmailAddress: payload.EmailAddress
        };
        const d = JSON.stringify(data);
        const h = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.userService.getToken()}`
        });
        return this.httpClient.post(`${this.baseURL}/sendinvites`, d, {headers: h});
    }
}
