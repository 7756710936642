import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {HttpService} from '../services/http.service';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    EMAIL_REGEXP = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
    NAME_REGEXP = /^([a-zA-Z0-9]+)$/;
    COMPANY_REGEXP = /^([a-zA-Z0-9\s]+)$/;
    US_PHONE_REGEXP = /^\([2-9]\d\d\) [2-9]\d\d\s-\s\d{4}$/;
    isError = false;
    reqInProgress = false;
    errorMsg = '';
    submitBtnTxt = 'SUBMIT';
    user = {
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        phone: '',
        password: '',
        cPassword: '',
    };
    errors = {
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        phone: '',
        password: '',
        cPassword: ''
    };

    constructor(private httpService: HttpService, private userService: UserService) {
    }

    ngOnInit() {
    }

    isNumericInput(event: KeyboardEvent): boolean {
        const key = (event.keyCode) || 0;
        return ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)
        );
    }

    isModifierKey(event: KeyboardEvent): boolean {
        const key = (event.keyCode) || 0;
        return (event.shiftKey === true || key === 35 || key === 36) ||
            (key === 8 || key === 9 || key === 13 || key === 46) ||
            (key > 36 && key < 41) || ((event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90));
    }

    enforceFormat(event: KeyboardEvent) {
        if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
            event.preventDefault();
        }
    }

    formatToPhone(event) {
        if (this.isModifierKey(event)) {
            return;
        }
        const input = this.user.phone.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
        const zip = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);

        if (input.length > 6) {
            this.user.phone = `(${zip}) ${middle} - ${last}`;
        } else if (input.length > 3) {
            this.user.phone = `(${zip}) ${middle}`;
        } else if (input.length > 0) {
            this.user.phone = `(${zip}`;
        }
    }

    validateRegistrationFields() {
        this.isError = false;

        if (this.user.firstName.trim().length === 0) {
            this.isError = true;
            this.errors.firstName = 'This field is required';
        } else if (!this.NAME_REGEXP.test(this.user.firstName.trim())) {
            this.isError = true;
            this.errors.firstName = 'Should contain only a-z and 0-9';
        } else if (this.user.firstName.trim().length > 50) {
            this.isError = true;
            this.errors.firstName = 'Should not be more than 50 characters';
        } else {
            this.errors.firstName = '';
        }

        if (this.user.lastName.trim().length === 0) {
            this.isError = true;
            this.errors.lastName = 'This field is required';
        } else if (!this.NAME_REGEXP.test(this.user.lastName.trim())) {
            this.isError = true;
            this.errors.lastName = 'Should contain only a-z and 0-9';
        } else if (this.user.lastName.trim().length > 50) {
            this.isError = true;
            this.errors.lastName = 'Should not be more than 50 characters';
        } else {
            this.errors.lastName = '';
        }

       /* if (this.user.companyName.trim().length === 0) {
            this.isError = true;
            this.errors.companyName = 'This field is required';
        } else if (!this.COMPANY_REGEXP.test(this.user.companyName.trim())) {
            this.isError = true;
            this.errors.companyName = 'Should contain only a-z, 0-9 and spaces';
        } else if (this.user.companyName.trim().length > 100) {
            this.isError = true;
            this.errors.companyName = 'Should not be more than 20 characters';
        } else {
            this.errors.companyName = '';
        }*/

        if (this.user.email.trim().length === 0) {
            this.isError = true;
            this.errors.email = 'This field is required';
        } else if (!this.EMAIL_REGEXP.test(this.user.email.trim())) {
            this.isError = true;
            this.errors.email = 'Please provide a valid email address';
        } else {
            this.errors.email = '';
        }

        if (this.user.phone.trim().length === 0) {
            this.isError = true;
            this.errors.phone = 'This field is required';
        } else if (!this.US_PHONE_REGEXP.test(this.user.phone.trim())) {
            this.isError = true;
            this.errors.phone = 'should be a valid US phone number';
        } else if (this.user.phone.trim().length > 16) {
            this.isError = true;
            this.errors.phone = 'Please provide a valid phone number';
        } else {
            this.errors.phone = '';
        }

        if (this.user.password.trim().length === 0) {
            this.isError = true;
            this.errors.password = 'This field is required';
        } else if (this.user.password.length < 8) {
            this.isError = true;
            this.errors.password = 'Should be minimum of 8 characters';
        } else if (this.user.password.length > 16) {
            this.isError = true;
            this.errors.password = 'Shouldn\'t be more than 16 characters';
        } else {
            this.errors.password = '';
        }

        if (this.user.cPassword.trim().length === 0) {
            this.isError = true;
            this.errors.cPassword = 'This field is required';
        } else if (this.user.password !== this.user.cPassword) {
            this.isError = true;
            this.errors.cPassword = 'Password doesn\'t match with confirm password';
        } else {
            this.errors.cPassword = '';
        }

        return this.isError;
    }

    clearForm(event: KeyboardEvent, field: string) {
        if (field === 'fn') {
            this.errors.firstName = '';
        } else if (field === 'ln') {
            this.errors.lastName = '';
        } else if (field === 'em') {
            this.errors.email = '';
        } else if (field === 'cn') {
            this.errors.companyName = '';
        } else if (field === 'ph') {
            this.errors.phone = '';
        } else if (field === 'pw') {
            this.errors.password = '';
        } else if (field === 'cpw') {
            this.errors.cPassword = '';
        }
    }

    doLogin() {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userInfo');
        this.httpService.login(this.user.email, this.user.password).subscribe((resp: any) => {
            if (resp.responseCode === 1) {
                const data = (resp.data) || {};
                const token = (data.token) || '';
                const userInfo = (data.userInfo) || {};
                localStorage.setItem('authToken', token);
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                window.location.href = `${window.location.protocol}//${window.location.host}/#/dashboard`;
                window.location.reload(true);
            }
        }, (errorResponse: HttpErrorResponse) => {
            this.isError = true;
            this.errorMsg = (errorResponse.status === 401) ? 'Invalid Login or password' : errorResponse.message;
        });
    }

    doSignUp() {
        this.errorMsg = '';
        if (!this.validateRegistrationFields() && !this.reqInProgress) {
            this.reqInProgress = true;
            this.submitBtnTxt = 'Please wait...';
            this.httpService.signUp(this.user).subscribe((resp: any) => {
                this.reqInProgress = false;
                this.submitBtnTxt = 'SUBMIT';
                if (resp.responseCode === 1) {
                    this.doLogin();
                }
            }, (errorResponse: HttpErrorResponse) => {
                console.log(errorResponse);
                this.reqInProgress = false;
                this.submitBtnTxt = 'SUBMIT';
                this.isError = true;
                this.errorMsg = errorResponse.error ? errorResponse.error.message
                    : 'Sorry, unable to complete your request, please refresh this page and try again';
            });
        }
    }

}
