import {Component, OnInit} from '@angular/core';
import {HttpService} from '../services/http.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import { EncodeURIPipe } from 'angular-pipes';
import * as moment from 'moment';


@Component({
  selector: 'app-event-category',
  templateUrl: './event-category.component.html',
  styleUrls: ['./event-category.component.css']
})
export class EventCategoryComponent implements OnInit {
  id: any;
  categoryName : '' ;
  isError: boolean;
  errorMsg: String ;
  data = [];
  ready = false;
  parentName = 'dashboard';
  reqInProgress = false;

  constructor(private route: ActivatedRoute,private router: Router, private httpService: HttpService, private userService: UserService) {
  }

  isDataEmpty() {
    return !(this.data.length > 0);
}

deleteEvent(cardObj) {
  console.log('before: ' + this.data.length);
  this.data.splice(cardObj, 1);
  console.log('after: ' + this.data.length);
}

updateLikes(cardObj) {
  const index = this.data.findIndex((obj => obj.scheduleID === cardObj.scheduleID));
  this.data[index].liked = true;
}

  ngOnInit() {
    this.route.params.subscribe(params => {
      console.log(params)
      this.id = params['id'];
      this.categoryName = params['category'];

  });

  this.httpService.getCategoryDetails(this.id).subscribe((resp: any) => {
 if (resp.responseCode === 1) {
        this.ready = true;
        this.isError = false;
        const tmpData = (resp.data) || [];
        const tmpDataNew = [];
        tmpData.forEach(function (v) {
            const tmp = v;
            tmp.isError = false;
            tmp.errorMsg = '';
            tmp.reqInProgress = false;
            tmp.buttonText = 'Delete';
            tmp.deleteReason = '';
            tmpDataNew.push(tmp);
        });
        this.data = tmpDataNew;
    }
},
(errorResponse: HttpErrorResponse) => {

  this.isError = true;


});
  }

}
