import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {HttpService} from '../services/http.service';

@Component({
    selector: 'app-event-card',
    templateUrl: './event-card.component.html',
    styleUrls: ['./event-card.component.css']
})
export class EventCardComponent implements OnInit {

    @Input() card: any;
    @Input('parent') parentName: string;
    @Input('ishide') hideEdit: boolean;

    @Output() deleteEventFromParent = new EventEmitter();
    @Output() updateLikeOnParent = new EventEmitter();

    constructor(private httpService: HttpService) {
    }

    formatDate(dt, time) {
        return `${dt.substr(0, dt.indexOf('T'))} ${time}`;
    }

    buildAddressLine(event: any): string {
        let finalAddrs = '';
        if (event.suiteNumber) {
            finalAddrs += event.suiteNumber;
        }
        if (event.street) {
            finalAddrs = (finalAddrs.trim().length > 0) ? `${finalAddrs} ` : finalAddrs;
            finalAddrs += event.street;
        }
        if (event.city) {
            finalAddrs = (finalAddrs.trim().length > 0) ? `${finalAddrs} ` : finalAddrs;
            finalAddrs += event.city;
        }
        if (event.stateName) {
            finalAddrs = (finalAddrs.trim().length > 0) ? `${finalAddrs} ` : finalAddrs;
            finalAddrs += event.stateName;
        }
        if (event.zipCode) {
            finalAddrs = (finalAddrs.trim().length > 0) ? `${finalAddrs} ` : finalAddrs;
            finalAddrs += event.zipCode;
        }
        return finalAddrs;
    }

    deleteEvent(cardObj) {
        if (cardObj.deleteReason.trim().length === 0) {
            cardObj.isError = true;
            cardObj.errorMsg = 'Please provide reason for delete';
            return;
        }
        cardObj.reqInProgress = true;
        cardObj.buttonText = 'Please wait...';
        this.httpService.deleteEvent(cardObj)
            .subscribe((resp: any) => {
                cardObj.reqInProgress = false;
                cardObj.submitBtnTxt = 'Delete';
                if (resp.responseCode === 1) {
                    var elementId =  document.getElementById(`delete-event-${cardObj.scheduleID}`);
                     elementId.remove();

                  //  document.getElementById(`delete-event-${cardObj.scheduleID}`).style.display = '';
                 this.deleteEventFromParent.emit(cardObj);
                 window.location.reload(true);
                }
            }, (errorResponse: HttpErrorResponse) => {
                console.log(errorResponse);
                cardObj.reqInProgress = false;
                cardObj.buttonText = 'Delete';
                cardObj.isError = true;
                cardObj.errorMsg = errorResponse.error ? errorResponse.error.message
                    : 'Sorry, unable to complete your request, please refresh this page and try again';
            });
    }

    likeEvent(cardObj) {
        if (!cardObj.liked) {
            cardObj.reqInProgress = true;
            this.httpService.likeEvent(cardObj.scheduleID)
                .subscribe((resp: any) => {
                    cardObj.reqInProgress = false;
                    if (resp.responseCode === 1) {
                        cardObj.liked = true;
                        cardObj.likesCount = cardObj.likesCount+1;
                        this.updateLikeOnParent.emit(cardObj);
                    }
                }, (errorResponse: HttpErrorResponse) => {
                    console.log(errorResponse);
                    cardObj.reqInProgress = false;
                });
        }
    }

    ngOnInit() {
    }

}
