import {Component, OnInit} from '@angular/core';
import {HttpService} from '../services/http.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import * as moment from 'moment';

@Component({
    selector: 'app-event-details',
    templateUrl: './event-details.component.html',
    styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {

    id: any;
    type: any;
    details:any;
    suggestevents : any ;

    detailsObj = {
    scheduleName: '',
    userName: '',
    scheduleLink: '',
    eventDate: '',
    eventEndDate: '',
    eventStartDate: '' ,

    scheduleDesc: '' ,
    companyName: ''

    };

    constructor(private route: ActivatedRoute,private router: Router, private httpService: HttpService, private userService: UserService) {
    }
    constructElement () {
        this.detailsObj.scheduleDesc = (this.type === 'event') ? this.details[0].eventDescription : this.details[0].confDescription;
        this.detailsObj.scheduleName = (this.type === 'event') ?  this.details[0].eventName : this.details[0].conferenceName;
        this.detailsObj.userName     = this.details.userFullName;
        this.detailsObj.scheduleLink = (this.type === 'event') ?  this.details[0].eventWebLink : this.details[0].confWebLink;
        var newdate  =  (this.type === 'event') ? moment(this.details[0].eventStartDate).format('dddd,MMMM D,YYYY'): moment(this.details[0].confStartDate).format('dddd,MMMM D,YYYY')
        var enddatetime  =  (this.type === 'event') ? moment(this.details[0].eventEndDate).format('dddd,MMMM D,YYYY ,hh:mm  A'): moment(this.details[0].confEndDate).format('dddd,MMMM D,YYYY, hh:mm A')
        var startdatetime  =  (this.type === 'event') ? moment(this.details[0].eventStartDate).format('dddd,MMMM D,YYYY ,hh:mm  A'): moment(this.details[0].confStartDate).format('dddd,MMMM D,YYYY, hh:mm A')

        this.detailsObj.eventDate = newdate;
        this.detailsObj.eventEndDate = enddatetime;
        this.detailsObj.eventStartDate = startdatetime ;
        this.detailsObj.companyName = this.details[0].companyName;
        




    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.id = params['id'];
            this.type = params['type'];
        });

        this.httpService.getSuggestEvents(this.id).subscribe((resp: any) => {
            if (resp.responseCode === 1) {
                this.suggestevents = resp.data;

            }
        });
        
        this.httpService.getEventDetails(this.id).subscribe((resp: any) => {
            if (resp.responseCode === 1) {
                this.details = resp.data;

                console.log(this.details);
               this.constructElement();
            }
        });
       
    }

}
