import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {FeaturesComponent} from './features/features.component';
import {HowitworksComponent} from './howitworks/howitworks.component';
import {FaqComponent} from './faq/faq.component';
import {SupportComponent} from './support/support.component';
import {AboutComponent} from './about/about.component';
import {EventRegistrationComponent} from './event-registration/event-registration.component';
import {ConfrenceRegistrationComponent} from './confrence-registration/confrence-registration.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {RegisterComponent} from './register/register.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {AuthGuard} from './auth/auth.guard';
import {EventDetailsComponent} from './event-details/event-details.component';
import {InviteComponent} from './invite/invite.component';
import {EventCategoryComponent} from './event-category/event-category.component';
import {SearchComponent} from './search/search.component';
import { AlleventsComponent } from './allevents/allevents.component';
import { AllcategoryComponent } from './allcategory/allcategory.component';


const routes: Routes = [
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: 'home', component: HomeComponent},
    {path: 'home/:component/:actcode', component: HomeComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'features', component: FeaturesComponent},
    {path: 'howitworks', component: HowitworksComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'support', component: SupportComponent},
    {path: 'about', component: AboutComponent},
    {path: 'event', component: EventRegistrationComponent, canActivate: [AuthGuard]},
    {path: 'conference', component: ConfrenceRegistrationComponent, canActivate: [AuthGuard]},
    {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
    {path: 'changePass', component: ChangePasswordComponent, canActivate: [AuthGuard]},
    {path: 'details/:type/:id', component: EventDetailsComponent},
    {path: 'event/:id', component: EventRegistrationComponent, canActivate: [AuthGuard]},
    {path: 'conference/:id', component: ConfrenceRegistrationComponent, canActivate: [AuthGuard]},
    {path: 'category/:category/:id', component: EventCategoryComponent },
    {path: 'invite/:type/:id', component: InviteComponent},
    {path: 'allcategory', component: AllcategoryComponent},
    {path: 'allevents', component: AlleventsComponent},
    {path: 'search', component: SearchComponent}



];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule],
    declarations: []
})
export class AppRoutingModule {
}
