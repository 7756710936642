import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import {HttpService} from '../services/http.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
    isError = false;
    reqInProgress = false;
    errorMsg = '';
    successMsg = '';
    submitBtnTxt = 'Submit';
    fields = {
        userId: 0,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    };
    errors = {
        oldPass: '',
        newPass: '',
        confirmPass: '',
    };

    constructor(private userService: UserService, private httpService: HttpService) {
    }

    ngOnInit() {
        this.fields.userId = this.userService.getLoginUserId();
        console.log(this.fields);
    }

    clearForm(event: KeyboardEvent, field: string) {
        if (field === 'op') {
            this.errors.oldPass = '';
        } else if (field === 'np') {
            this.errors.newPass = '';
        } else if (field === 'cp') {
            this.errors.confirmPass = '';
        }
    }

    doValidation(): boolean {
        this.isError = false;
        if (this.fields.oldPassword.trim().length === 0) {
            this.isError = true;
            this.errors.oldPass = 'This field is required';
        } else if (this.fields.oldPassword.length < 8) {
            this.isError = true;
            this.errors.oldPass = 'Should be minimum of 8 characters';
        } else if (this.fields.oldPassword.length > 16) {
            this.isError = true;
            this.errors.oldPass = 'Shouldn\'t be more than 16 characters';
        } else {
            this.errors.oldPass = '';
        }

        if (this.fields.newPassword.trim().length === 0) {
            this.isError = true;
            this.errors.newPass = 'This field is required';
        } else if (this.fields.newPassword.length < 8) {
            this.isError = true;
            this.errors.newPass = 'Should be minimum of 8 characters';
        } else if (this.fields.newPassword.length > 16) {
            this.isError = true;
            this.errors.newPass = 'Shouldn\'t be more than 16 characters';
        } else {
            this.errors.newPass = '';
        }

        if (this.fields.confirmPassword.trim().length === 0) {
            this.isError = true;
            this.errors.confirmPass = 'This field is required';
        } else if (this.fields.confirmPassword !== this.fields.newPassword) {
            this.isError = true;
            this.errors.confirmPass = 'Password doesn\'t match with confirm password';
        } else {
            this.errors.newPass = '';
        }

        return this.isError;
    }

    doSubmit() {
        this.errorMsg = '';
        this.successMsg = '';
        if (!this.doValidation() && !this.reqInProgress) {
            this.reqInProgress = true;
            this.submitBtnTxt = 'Please wait...';
            this.httpService.changePassword(this.fields).subscribe((resp: any) => {
                this.reqInProgress = false;
                this.submitBtnTxt = 'SUBMIT';
                this.successMsg = 'Password Reset Successfully. Please login with your new password';
                this.userService.logoutUser();
                this.fields.userId = 0;
                this.fields.oldPassword = '';
                this.fields.newPassword = '';
                this.fields.confirmPassword = '';
                setTimeout(() => {
                    window.location.reload(true);
                }, 1500);
                console.log(resp);
            }, (errorResponse: HttpErrorResponse) => {
                console.log(errorResponse);
                this.reqInProgress = false;
                this.submitBtnTxt = 'SUBMIT';
                this.isError = true;
                this.errorMsg = errorResponse.error ? errorResponse.error.message
                    : 'Sorry, unable to complete your request, please refresh this page and try again';
            });
        }
    }
}
