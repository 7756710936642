import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import {HttpService} from '../services/http.service';

@Component({
    selector: 'app-invite',
    templateUrl: './invite.component.html',
    styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit {
    readonly EMAIL_REGEXP = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
    eventId: any;
    userId: any;
    inviteObj: any ;
    inviteId:any ;
    type: any;
    emailText: string;
    emailsList = [];
    error = '';
    errorMsg = '';
    successMsg = '';
    reqInProgress = false;
    submitBtnTxt = 'Send Invite';

    constructor(private route: ActivatedRoute, private router: Router,
         private httpService: HttpService, private userService: UserService) {
    }

    isEmpty() {
        return !(this.emailsList.length > 0);
    }

    clearError() {
        this.error = '';
    }

    processEmails() {
        this.error = '';
        if (this.emailText.trim() !== '') {
            const emails = this.emailText.split(',');
            for (let i = 0; i < emails.length; i++) {
                const e = emails[i].trim();
                if (this.EMAIL_REGEXP.test(e) && !this.emailsList.includes(e)) {
                    this.emailsList.push(e);
                } else {
                    if (this.error.trim().length > 0) {
                        this.error += '<br/>';
                    }
                    if (!this.EMAIL_REGEXP.test(emails[i])) {
                        this.error += `${emails[i]} is not a valid email`;
                    }
                    if (this.emailsList.includes(emails[i])) {
                        this.error += `${emails[i]} already exists`;
                    }
                }
            }
            this.emailText = '';
        }
    }

    findIndexInData(data, property, value) {
        for(var i = 0, l = data.length ; i < l ; i++) {
          if(data[i][property] === value) {
            return i;
          }
        }
        return -1;
      }

    removeEmail(email) {

        var index = this.emailsList.indexOf(email);
        var indexId = this.findIndexInData(this.inviteObj,'email',email);
         this.inviteId = this.inviteObj[indexId].invitationID;
       this.emailsList.splice(index, 1);

       const payload = {
            invitationId:this.inviteId,
            scheduleId: this.eventId  }
       this.httpService.removeInvite( payload)
            .subscribe((resp: any) => {
                if (resp.responseCode === 1) {
                    this.successMsg = "Invitees removed successfully";  
                }
            }, (errorResponse: HttpErrorResponse) => {
                console.log(errorResponse);
                this.reqInProgress = false;
                this.submitBtnTxt = 'Send Invite';
                this.errorMsg = errorResponse.error ? errorResponse.error.message
                    : 'Sorry, unable to complete your request, please refresh this page and try again';
            });

    }

    sendInvites() {
        const payload = {
            UserID: this.userId,
            ScheduleID: this.eventId,
            EmailAddress: this.emailsList
        };
        this.errorMsg = '';
        this.httpService.sendInvities(payload).subscribe((resp: any) => {
            this.reqInProgress = false;
            this.submitBtnTxt = 'Send Invite';
            if (resp.responseCode === 1) {
                setTimeout(() => {
                    this.successMsg = 'Event created successfully and emails sent to your invitees.';
                    setTimeout(() => {
                        this.router.navigate(['/dashboard']);
                    }, 2000);
                }, 2000);
            }
        }, (errorResponse: HttpErrorResponse) => {
            console.log(errorResponse);
            this.reqInProgress = false;
            this.submitBtnTxt = 'Send Invite';
            this.errorMsg = errorResponse.error ? errorResponse.error.message
                : 'Sorry, unable to complete your request, please refresh this page and try again';
        });
    }

    ngOnInit() {
        this.route.params
            .subscribe(params => {
                this.eventId = params['id'];
                this.type = params['type'];
            });
        this.httpService.getInviteesList(this.eventId)
            .subscribe((resp: any) => {
                if (resp.responseCode === 1) {
                    const tmpData = (resp.data) || [];
                    this.inviteObj = resp.data ;
                    const tmpInviteesList = [];
                    tmpData.forEach(v => {
                        tmpInviteesList.push(v.email);
                    });
                    this.emailsList.push(...tmpInviteesList);
                }
            }, (errorResponse: HttpErrorResponse) => {
                console.log(errorResponse);
                this.reqInProgress = false;
                this.submitBtnTxt = 'Send Invite';
                this.errorMsg = errorResponse.error ? errorResponse.error.message
                    : 'Sorry, unable to complete your request, please refresh this page and try again';
            });
    }

}
