import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UserService} from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private userService: UserService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const helper = new JwtHelperService();
        const token = localStorage.getItem('authToken');
        if (token === null || token.length === 0) {
            this.router.navigate(['/home']);
            return false;
        }
        if (helper.isTokenExpired(token)) {
            this.userService.logoutUser();
            this.router.navigate(['/home']);
            return false;
        }
        return true;
    }
}
