import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor() {
    }

    isLoggedIn(): boolean {
        const token = localStorage.getItem('authToken');
        return token != null && token.trim().length > 0;
    }

    getLoginUser(): string {
        let userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo !== '' ? JSON.parse(userInfo) : {};
        return (userInfo) ? userInfo['firstName'] : '';
    }

    getLoginUserId(): number {
        let userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo !== '' ? JSON.parse(userInfo) : {};
        return (userInfo) ? userInfo['userID'] : 0;
    }

    getLoginUserEmail(): number {
        let userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo !== '' ? JSON.parse(userInfo) : {};
        return (userInfo) ? userInfo['emailLogin'] : '';
    }

    getToken(): string {
        const token = localStorage.getItem('authToken');
        return (token != null && token.trim().length > 0) ? token : null;
    }

    logoutUser() {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userInfo');
    }
}
