import {Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import {HttpService} from '../services/http.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {UserService} from '../services/user.service';





@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    @ViewChild('openModal') openModal:ElementRef;

    EMAIL_REGEXP = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
    reqInProgress = false;
    isError = false;
    isEmailError = false;
    isPasswordError = false;
    scheduleId:any;
    isLoggedIn = false;
    public forgotemail = '' ;
    forgotsuccessMsg = '' ;
    errorMsg = '';
    successMsg = '';
    loginUserName = '';
    submitBtnTxt = 'SUBMIT';
    joinEvent = {
        privateCode: '',
        scheduleId: 0,
        
    };
    user = {
        firstName: '',
        email: '',
        password: '',
    };
    errors = {
        joinEvent: ''
    };

    constructor(private httpService: HttpService, private router: Router, private userService: UserService) {
    }

    ngOnInit() {
        // opening
      
        this.isLoggedIn = this.userService.isLoggedIn();
        this.loginUserName = this.userService.getLoginUser();
    }
    myFunc(){
        this.openModal.nativeElement.click();
    }

    closeJoinEvent() {
        document.getElementById('joint-modal').style.display = 'none';
    }

    validateJoinEvent() {
        const privateCode = this.joinEvent.privateCode;
        if (privateCode === '') {
            this.isError = true;
            this.errors.joinEvent = 'Please enter your private code ';
        } else {
            this.errors.joinEvent = '';
            this.reqInProgress = true;
            this.submitBtnTxt = 'Please wait...';
            this.httpService.getEventByPrivatecode(privateCode).subscribe((resp: any) => {
                this.reqInProgress = false;
                this.submitBtnTxt = 'SUBMIT';
                if (resp.responseCode === 1) {
                    this.scheduleId = resp.data.scheduleID;
                    if(this.scheduleId > 0 ){
                        this.joinEvent.scheduleId = this.scheduleId;

                        this.httpService.joinEvent(this.joinEvent).subscribe((resp: any) => {
                           
                            if (resp.responseCode === 1) {
                                 this.errorMsg = '';
                                  this.successMsg = "Event Joined successfully";	

                                setTimeout(() => {
                               this.closeJoinEvent();
                            }, 2000);
                            }else {
                                this.errors.joinEvent = resp.message;
                               
                                
                            }
                        },( errorResponse: HttpErrorResponse) => {
                		console.log(errorResponse);
                                this.errorMsg = errorResponse.error.message;
                        });


                    }
                }
            }, ( errorResponse: HttpErrorResponse) => {
                console.log(errorResponse);
           
               this.errorMsg = errorResponse.error.message;
                
                this.reqInProgress = false;
                this.submitBtnTxt = 'Save';
                this.isError = true;
                this.errorMsg = errorResponse.error ? errorResponse.error.message
                    : 'Sorry, unable to complete your request, please refresh this page and try again';
            });
        }
    }

    validateFields(): boolean {
        const email = this.user.email;
        const pwd = this.user.password;
        if (email === '' || email.trim().length === 0) {
            this.isEmailError = true;
        }
        if (pwd === '' || pwd.trim().length === 0) {
            this.isPasswordError = true;
        }
        if (this.isEmailError && this.isPasswordError) {
            this.errorMsg = 'Email and password field is required';
        } else if (this.isEmailError) {
            this.errorMsg = 'Email field is required';
        } else if (this.isPasswordError) {
            this.errorMsg = 'Password field is required';
        } else if (!this.EMAIL_REGEXP.test(email)) {
            this.isEmailError = true;
            this.errorMsg = 'Please provide a valid email address';
        }
        return (!this.isEmailError && !this.isPasswordError);
    }

    clearForm(event: KeyboardEvent) {
        this.isError = false;
        this.isEmailError = false;
        this.isPasswordError = false;
        this.errorMsg = '';
        if (event.keyCode === 13) {
            this.doLogin();
        }
    }

    forgotpassword() {
       if (this.forgotemail.trim().length === 0) {
        this.errorMsg = 'Please provide a  email address';

       }
        if (!this.EMAIL_REGEXP.test(this.forgotemail)) {
            this.errorMsg = 'Please provide a valid email address';
        } else { 
            this.httpService.forgot(this.forgotemail).subscribe((resp: any) => {
                this.reqInProgress = false;
                this.submitBtnTxt = 'SUBMIT';
                if (resp.responseCode === 1) {
                    this.forgotsuccessMsg= "Forgot email send successfully";
                    this.errorMsg = '' ;
                    setTimeout(() => {
                        document.getElementById('forgot-password').style.display = 'none';
                    }, 2000);
              }
            }, (errorResponse: HttpErrorResponse) => {
                this.reqInProgress = false;
                this.submitBtnTxt = 'SUBMIT';
                this.isError = true;
                this.errorMsg = (errorResponse.status === 401) ? 'Invalid Login or password' : errorResponse.message;
            });


        }

    }

    doLogin() {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userInfo');
        this.loginUserName = '';
        this.isLoggedIn = false;
        if (this.validateFields() && !this.reqInProgress) {
            this.reqInProgress = true;
            this.submitBtnTxt = 'Please wait...';
            this.httpService.login(this.user.email, this.user.password).subscribe((resp: any) => {
                this.reqInProgress = false;
                this.submitBtnTxt = 'SUBMIT';
                if (resp.responseCode === 1) {
                    const data = (resp.data) || {};
                    const token = (data.token) || '';
                    const userInfo = (data.userInfo) || {};
                    localStorage.setItem('authToken', token);
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    this.loginUserName = (userInfo['firstName']) || '';
                    this.isLoggedIn = true;
                    document.getElementById('login-modal').style.display = 'none';
                    window.location.href = `${window.location.protocol}//${window.location.host}/#/dashboard`;
                    /*this.router.navigate(['/dashboard']);*/
                }
            }, (errorResponse: HttpErrorResponse) => {
                this.reqInProgress = false;
                this.submitBtnTxt = 'SUBMIT';
                this.isError = true;
                this.errorMsg = (errorResponse.status === 401) ? 'Invalid Login or password' : errorResponse.message;
            });
        }
    }

    doLogOut() {
        this.isLoggedIn = false;
        localStorage.removeItem('authToken');
        localStorage.removeItem('userInfo');
        this.loginUserName = '';
       // document.getElementById('hidelink').style.display = 'none'; alert('test')
        window.location.href = `${window.location.protocol}//${window.location.host}/#/home`;
    }

    navRegPage() {
        this.router.navigate(['/register']);
    }
}
