import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {HttpService} from '../services/http.service';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardComponent implements OnInit {

    data = [];
    myeventData = [];
    ready = false;
    parentName = 'dashboard';
    results:any;
    reqInProgress = false;
    public min = new Date();
    hideEdit = false;
    isError = false;
    submitBtnTxt = 'Search';
    errorMsg = '';
    successMsg = '';
    tmp = [];

    search = {

        scheduleTypeId: '0' ,
        scheduleName : '' ,
        fromDate : '' ,
        toDate : '' ,
        listType : 0 ,
      };

    constructor(private router: Router, private httpService: HttpService) {
    }

    isDataEmpty() {
        return !(this.data.length > 0);
    }
    ismyeventEmpty(){
        return !(this.myeventData.length > 0); 
    }

    deleteEvent(cardObj) {
        console.log('before: ' + this.data.length);
        //this.data.splice(cardObj, 1);
        const index = this.data.findIndex((obj => obj.scheduleID === cardObj.scheduleID));

        this.data.splice(cardObj, index);

        console.log('after: ' + this.data.length);
    }
    clearform() {
        this.search.scheduleTypeId = '0';
        this.search.scheduleName = '' ;
        this.search.fromDate = '' ;
        this.search.toDate = '' ;
        this.search.listType = 0 ;


    }

    updateLikes(cardObj) {

        const index = this.data.findIndex((obj => obj.scheduleID === cardObj.scheduleID));
        this.data[index].liked = true;
    }calculate(id, status) {
        const indexkey = parseInt(id, 10) - 1;
   if (status === true) {
            this.tmp.push(id);

        }
        else {
            const index = this.tmp.indexOf(id);
            if (index > -1) {
                this.tmp.splice(index, 1);
            }
    
        }
        if(this.tmp.length == 2){
          this.search.listType = 0;
        } 
        else if(this.tmp.length ==1){
          this.search.listType = ( this.tmp[0] == 2) ? 2 : 1 ;
  }
    
    }


    clearfrm(){
        this.search.scheduleTypeId = '0';
        this.search.scheduleName = '';
        this.search.fromDate = '';
        this.search.toDate = '';
      }
      
      callsearch() {
        
        this.httpService.searchDashboardEvent(this.search).subscribe((resp: any) => {
          this.reqInProgress = false;
          this.submitBtnTxt = 'Save';
	this.data = [];
          if (resp.body != null && resp.body.responseCode === 1) {
            this.data = resp.body.data;
            if(this.search.listType ==2){
                this.hideEdit = true;
            } else {
                this.hideEdit = false;
            }
           
                  this.successMsg = resp.body.message;
                
              this.clearform();
              this.reqInProgress = true;

    
          } else {
              this.isError = true;
              this.errorMsg = resp.body.message;
              
          }
      }, (errorResponse: HttpErrorResponse) => {
          console.log(errorResponse);
          this.reqInProgress = false;
          this.submitBtnTxt = 'Save';
          this.data = [];
          this.isError = true;
          this.errorMsg = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
      });
      }
      submitfrm(){
       
        this.search.fromDate = (this.search.fromDate !== '') ? moment(this.search.fromDate).format('YYYY/MM/DD') : '';
        this.search.toDate = (this.search.toDate !== '') ? moment(this.search.toDate).format('YYYY/MM/DD') : '';
        console.log(this.search);
        this.callsearch();    
      }

    ngOnInit() {
        this.httpService.getUserDashboard().subscribe((resp: any) => {
            this.ready = true;
            if (resp.responseCode === 1) {
                const tmpData = (resp.data) || [];
                const tmpDataNew = [];
                tmpData.forEach(function (v) {
                    const tmp = v;
                    tmp.isError = false;
                    tmp.errorMsg = '';
                    tmp.reqInProgress = false;
                    tmp.buttonText = 'Delete';
                    tmp.deleteReason = '';
                    tmpDataNew.push(tmp);
                });
                this.myeventData = tmpDataNew;
                this.data = this.myeventData ;
               
            }
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.ready = true;
        });
    }
}
