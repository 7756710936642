import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {HttpService} from '../services/http.service';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  scheduleTypes: any;
  scheduleCategories: any;
  results : any ;
   isError = false;
  reqInProgress = false;
  submitBtnTxt = 'Search';
  errorMsg = '';
  successMsg = '';
  tmp = [];




  search = {

    scheduleCategoryTypeID: 0,
    countryId: 1 ,
    scheduleTypeId: 0 ,
    scheduleName : '' ,
    fromDate : '' ,
    toDate : '' ,
    location : '' ,
    userId:0 ,
    
  };

  constructor(private route: ActivatedRoute,private router: Router, private httpService: HttpService, private userService: UserService) {
  }

  calculate(id, status) {
    const indexkey = parseInt(id, 10) - 1;

    if (status === true) {
        this.tmp.push(id);

    }
    else {
        const index = this.tmp.indexOf(id);
        if (index > -1) {
            this.tmp.splice(index, 1);
        }

    }
    /*if(this.tmp.length == 2){
      this.search.listType = 0;
    } 
    else if(this.tmp.length ==1){
      this.search.listType = ( this.tmp[0] == 2) ? 2 : 1 ;

    }*/

}
isDataEmpty() {
  return !(this.results.length > 0);
}

clearfrm(){
  this.search.scheduleTypeId = 0;
  this.search.scheduleCategoryTypeID=0;
  this.search.scheduleName = '';
  this.search.location = '';
  this.search.fromDate = '';
  this.search.toDate = '';
}

  callsearch() {
    //this.search.userId = this.userService.getLoginUserId();
    this.httpService.searchEvent(this.search).subscribe((resp: any) => {
      this.reqInProgress = false;
      this.submitBtnTxt = 'Save';
      console.log(this.search);
      if (resp.body != null && resp.body.responseCode === 1) {
        this.results = resp.body.data;
          /*setTimeout(() => {
              this.successMsg = resp.message;
            //  window.scrollTo(0, 0);
             
          }, 1000);*/

      } else {
          this.isError = true;
          this.errorMsg = resp.message;
          this.results= [];
          this.results.length =0;
      }
  }, (errorResponse: HttpErrorResponse) => {
      console.log(errorResponse);
      this.reqInProgress = false;
      this.submitBtnTxt = 'Save';
      this.isError = true;
      this.errorMsg = errorResponse.error ? errorResponse.error.message
          : 'Sorry, unable to complete your request, please refresh this page and try again';
  });
  }
  submitfrm(){
    
    const frmDate = this.search.fromDate ;
    const toDate = this.search.toDate ;

    this.search.fromDate = (this.search.fromDate !== '') ? moment(this.search.fromDate).format('YYYY/MM/DD') : '';
    this.search.toDate = (this.search.toDate !== '') ? moment(this.search.toDate).format('YYYY/MM/DD') : '';
    this.callsearch();
    this.search.fromDate = frmDate;
    this.search.toDate = toDate ;
  }
  ngOnInit() {

    this.httpService.getScheduleTypes().subscribe((resp: any) => {
      if (resp.responseCode === 1) {
          this.scheduleTypes = resp.data;
      }
  });

    
  this.httpService.getScheduleCategories().subscribe((resp: any) => {
    if (resp.responseCode === 1) {
        this.scheduleCategories = resp.data;
    }
});

this.callsearch();

  }

}
