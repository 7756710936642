import {Component, OnInit} from '@angular/core';
import {HttpService} from '../services/http.service';
import { EncodeURIPipe } from 'angular-pipes';
import {UserService} from '../services/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';

@Component({
  selector: 'app-allcategory',
  templateUrl: './allcategory.component.html',
  styleUrls: ['./allcategory.component.css']
})
export class AllcategoryComponent implements OnInit {

  scheduleCategories:any;

  constructor(private httpService: HttpService) {

  }

  ngOnInit() {
    this.httpService.getScheduleCategories().subscribe((resp: any) => {
      if (resp.responseCode === 1) {
          this.scheduleCategories = resp.data;
      }
  });
  }

}
