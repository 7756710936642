import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  lat:any;
  lng:any;

  constructor() { 

   /* if (navigator)
    {
    navigator.geolocation.getCurrentPosition( pos => {
        this.lng = +pos.coords.longitude;
       this.lat = +pos.coords.latitude;

       /* this.lat = 40.7308;
        this.lng = -73.9975;
        

      });
}*/
  }

  ngOnInit() {
  }

}
