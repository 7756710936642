import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgArrayPipesModule, NgStringPipesModule } from 'angular-pipes';

import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';

import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {AppRoutingModule} from './/app-routing.module';
import {FeaturesComponent} from './features/features.component';
import {HowitworksComponent} from './howitworks/howitworks.component';
import {FaqComponent} from './faq/faq.component';
import {SupportComponent} from './support/support.component';
import {AboutComponent} from './about/about.component';
import {EventRegistrationComponent} from './event-registration/event-registration.component';
import {ConfrenceRegistrationComponent} from './confrence-registration/confrence-registration.component';
import {DashboardComponent} from './dashboard/dashboard.component';

import {UserService} from './services/user.service';
import {RegisterComponent} from './register/register.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {EventDetailsComponent} from './event-details/event-details.component';
import {InviteComponent} from './invite/invite.component';
import { EventCardComponent } from './event-card/event-card.component';
import { EventCategoryComponent } from './event-category/event-category.component';
import { SearchComponent } from './search/search.component';
import { AllcategoryComponent } from './allcategory/allcategory.component';
import { AlleventsComponent } from './allevents/allevents.component';
import { AgmCoreModule } from '@agm/core';



@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        FeaturesComponent,
        HowitworksComponent,
        FaqComponent,
        SupportComponent,
        AboutComponent,
        EventRegistrationComponent,
        ConfrenceRegistrationComponent,
        DashboardComponent,
        RegisterComponent,
        ChangePasswordComponent,
        EventDetailsComponent,
        InviteComponent,
        EventCardComponent,
        EventCategoryComponent,
        SearchComponent,
        AllcategoryComponent,
        AlleventsComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        NgArrayPipesModule,
        NgStringPipesModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCSIFuXPQXel1splGkx5ElXoU1bL60Jn-I'
          })
    ],
    providers: [
        UserService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
