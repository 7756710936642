import {Component, OnInit} from '@angular/core';
import {HttpService} from '../services/http.service';
import { EncodeURIPipe } from 'angular-pipes';
import {UserService} from '../services/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
@Component({
  selector: 'app-allevents',
  templateUrl: './allevents.component.html',
  styleUrls: ['./allevents.component.css']
})
export class AlleventsComponent implements OnInit {

  events: any[];
  ready = false;
  parentName = 'home';

  constructor(private httpService: HttpService) {

  }


  updateLikes(cardObj) {
    const index = this.events.findIndex((obj => obj.scheduleID === cardObj.scheduleID));
    this.events[index].liked = true;
}

  ngOnInit() {

    this.httpService.getEventsListHomePg().subscribe((resp: any) => {
      this.ready = true;
      let tmpList: any[];
      if (resp.responseCode === 1) {
          this.events = resp.data;
      }
 

  }, (err: HttpErrorResponse) => {
      this.ready = true;
      console.error(err);
  });

  }

}
