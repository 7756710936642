import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {HttpService} from '../services/http.service';
import {HttpErrorResponse} from '@angular/common/http';
import { HttpClient,HttpHeaders } from '@angular/common/http'

import * as moment from 'moment';

@Component({
    selector: 'app-event-registration',
    templateUrl: './event-registration.component.html',
    styleUrls: ['./event-registration.component.css']
})
export class EventRegistrationComponent implements OnInit {
    public min = new Date();
    public max = new Date(2018, 12, 25, 20, 30);
    public day1 = '';
    public day2 = '';
    public time1 = '' ;
    public time2 = '' ;
    linkData:any;
    readonly errMsgRequired = 'This field is required';
    errMsgDate = 'To date should be greater than from date ';
    ZIP_REGEX = /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *\d[A-Z]\d)$/;
    NUM_REGEX = /^[0-9]$/;
    isError = false;
    reqInProgress = false;
    submitBtnTxt = 'Save';
    public checkLinkFlag = true; 
    privateCode = '' ;
    eventId:any;
    editObj:any;
    errorMsg = '';
    successMsg = '';
    datePicker = {
        min: new Date()
    };

    country = {
        id: 1,
        code: 'US',
        name: 'UNITED STATES'
    };

    // prerequisites
    states: any[];
    scheduleGroups: any[];
    scheduleCategories: any[];

    errors = {
        userId: 0,
        scheduleCategoryTypeID: '',
        scheduleGroupTypeID: '',
        scheduleTypeId: '',
        scheduleType: '',
        eventName: '',
        suiteNo: '',
        street: '',
        city: '',
        state: '',
        zipCode: '',
        countryName: '',
        noOfDays: '',
        multiDate: '',
        eventStartDate: '',
        eventStartTime: '',
        eventEndDate: '',
        eventEndTime: '',
        ticketStartDate: '',
        ticketStartTime: '',
        ticketEndDate: '',
        ticketEndTime: '',
        ticketPriceRange: '',
        eventWebLink: '',
        privateCode: '',
        eventDescription: '',
    };

    event = {
        userId: '',
        eventId: 0 ,
        scheduleCategoryTypeID: 0,
        scheduleGroupTypeID: '',
        scheduleTypeId: '11',
        scheduleType: 'Event',
        eventName: '',
        suiteNo: '',
        street: '',
        city: '',
        state: '',
        stateID: 0,
        zipCode: '',
        countryId: `${this.country.id}`,
        countryName: this.country.name,
        noOfDays: '',
        multiDate: false,
        multiDateArr: [],
        EventDays: [],
        editEventDays:[],
        eventStartDate: '',
        eventStartTime: '',
        eventEndDate: '',
        eventEndTime: '',
        ticketStartDate: '',
        ticketStartTime: '',
        ticketEndDate: '',
        ticketEndTime: '',
        ticketPriceRange: '',
        eventWebLink: '',
        privateCode: '',
        eventDescription: '',
        fullDayEvent: false,
    };


    constructor(private route: ActivatedRoute,private http: HttpClient,
        private router: Router, private httpService: HttpService, private userService: UserService) {
    }

    showConferenceForm() {
        this.router.navigate(['/conference']);
    }
    constructEditObj(editObj){
        this.event.userId = editObj[0].userID;
        const eventCount = editObj[0].eventDays.length;
        this.event.scheduleCategoryTypeID = editObj[0].scheduleCategoryTypeID.toString();
        this.event.scheduleGroupTypeID = editObj[0].scheduleGroupTypeID;
        this.event.stateID = editObj[0].stateID;
        this.event.eventName = editObj[0].eventName;
        this.event.street = editObj[0].street;
        this.event.suiteNo = (editObj[0].suiteNo === null )? '':editObj[0].suiteNo;
        this.event.city = editObj[0].city;
        this.event.zipCode = editObj[0].zipCode;
        this.event.multiDate = editObj[0].multiDayEvent;
        this.event.eventDescription = editObj[0].eventDescription;
        this.event.eventWebLink = editObj[0].eventWebLink;
        this.event.privateCode = editObj[0].privateCode;
        this.event.eventStartDate = editObj[0].eventStartDate;
        this.event.eventStartTime = editObj[0].eventStartTime;
        this.event.eventEndDate = editObj[0].eventEndDate;
        this.event.eventEndTime = editObj[0].eventEndTime;
        this.event.ticketStartDate = (editObj[0].ticketStartDate == null)?  '' : editObj[0].ticketStartDate;
        this.event.ticketEndDate =   (editObj[0].ticketEndDate == null) ? '' : editObj[0].ticketEndDate;
        this.event.ticketStartTime = (editObj[0].ticketStartTime == null) ? '': editObj[0].ticketStartTime;
        this.event.ticketEndTime = (editObj[0].ticketEndTime == null) ? '' : editObj[0].ticketEndTime;
        this.event.noOfDays = eventCount.toString();
        this.event.editEventDays = editObj[0].eventDays;
        this.createMiniForm();
        for (let i = 0; i < editObj[0].eventDays.length; i++) {
            this.event.multiDateArr[i] = {
                startDt: editObj[0].eventDays[i].startDate,
                startTime:editObj[0].eventDays[i].startTime,
                endDt : editObj[0].eventDays[i].endDate,
                endTime:editObj[0].eventDays[i].endTime
               /*() this.event.EventDays[i] = {
                    StartDate:moment( this.event.multiDateArr[i].startDt).format('YYYY/MM/DD'),
                    StartTime: moment(this.event.multiDateArr[i].startTime).format('hh:mm'),
                    EndDate:  moment( this.event.multiDateArr[i].endDt).format('YYYY/MM/DD'),
                    EndTime: moment( this.event.multiDateArr[i].endTime).format('hh:mm')*/
                 };

               

        }

    }
   createEditMiniForm() {


    const days = parseInt(this.event.noOfDays, 10);
    this.event.multiDateArr =[];
      let startDate = '' ;
         let endDate = '';
        let startTime = '';
        let endTime = '' ;

    for (let i = 0; i < days; i++) {

   if( i >= this.event.editEventDays.length) {
      startDate = endDate =  startTime = endTime ='';
       

      } else {

         startDate = ( this.event.editEventDays[i].startDate!="" ) ? this.event.editEventDays[i].startDate  :'';
         endDate =   ( this.event.editEventDays[i].endDate!="") ? this.event.editEventDays[i].endDate  :'';
         startTime =  ( this.event.editEventDays[i].startTime!="") ? this.event.editEventDays[i].startTime  :'';
         endTime   = (this.event.editEventDays[i].endTime!="") ? this.event.editEventDays[i].endTime: '';

       }
            this.event.multiDateArr[i] = {
                startDt:startDate,
                startTime:endDate,
                endDt : startTime,
                endTime:endTime
              
                 };
        }
   }

    createMiniForm() {
        this.event.multiDateArr = [];
        this.errors.noOfDays = '';
        const days = parseInt(this.event.noOfDays, 10);

       if (days > 0) {
            for (let i = 0; i < days; i++) {
                 let j = i+1;


                this.event.multiDateArr[i] = {label: `Day ${j}`, '': '', startTime: '', endDt: '', endTime: ''};
       
            }
        }
    }

   disableevent(id,dateValue) {
   
 
   const splitter = id.split("-");
   const idString =splitter[1];
   

   
   if(dateValue!="") {
  
   this.event.multiDateArr[idString].endDt = this.event.multiDateArr[idString].startDt

   // document.getElementById("end-0").style.display='none';
 
   }
    
  

  }

    clearForm(event: KeyboardEvent, field: string) {
        if (field === 'en') {
            this.errors.eventName = '';
        } else if (field === 'st') {
            this.errors.street = '';
        } else if (field === 'sn') {
            this.errors.suiteNo = '';
        } else if (field === 'ci') {
            this.errors.city = '';
        } else if (field === 'zi') {
            this.errors.zipCode = '';
        } else if (field === 'ewl') {
            this.errors.eventWebLink = '';
        } else if (field === 'ed') {
            this.errors.eventDescription = '';
        } else if (field === 'pc') {
            this.errors.privateCode = '';
        }

        /*else if (field === 'esd') {
            this.errors.eventStartDate = '';
        } else if (field === 'est') {
            this.errors.eventStartTime = '';
        } else if (field === 'eed') {
            this.errors.eventEndDate = '';
        } else if (field === 'edt') {
            this.errors.eventEndTime = '';
        } else if (field === 'tsd') {
            this.errors.ticketStartDate = '';
        } else if (field === 'tst') {
            this.errors.ticketStartTime = '';
        } else if (field === 'ted') {
            this.errors.ticketEndDate = '';
        } else if (field === 'tet') {
            this.errors.ticketEndTime = '';
        } */
    }

    onChange(categoryId, flag) { 
        flag = parseInt(flag, 10);
        categoryId = parseInt(categoryId,10);
        if (categoryId > 0 && flag === 1) {
            this.errors.scheduleCategoryTypeID = '';
        } else if (categoryId > 0 && flag === 2) {
            this.errors.state = '';

        } else {
            (flag === 1) ? this.errors.scheduleCategoryTypeID = this.errMsgRequired : this.errors.state = this.errMsgRequired;
        }
    }
    



    doValidation() {
        this.isError = false;
        let mErrorMsg = '';
        this.event.multiDateArr.forEach(function (mDate) {
            if (mDate.startDt === '' || mDate.startTime === '' 	
               ) {
                mErrorMsg = '1';

            }

        });
           this.day1 = this.event.eventStartDate ;
                 this.event.eventEndDate  =(this.event.eventEndDate == "") ? this.event.eventEndDate : this.day1 ;
                 this.time1 = this.event.eventStartTime;
                 this.time2 = this.event.eventEndTime;

        if (this.event.scheduleCategoryTypeID === 0) {
            this.isError = true;
            this.errors.scheduleCategoryTypeID = this.errMsgRequired;
        }

        if (this.event.scheduleGroupTypeID.length === 0) {
            this.isError = true;
            this.errors.scheduleGroupTypeID = this.errMsgRequired;
        } else {
            this.errors.scheduleGroupTypeID = '';

        }
        if (this.event.eventName.trim().length === 0) {
            this.isError = true;
            this.errors.eventName = this.errMsgRequired;
        }
        if (this.event.street.trim().length === 0) {
            this.isError = true;
            this.errors.street = this.errMsgRequired;
        }
       /*if (this.event.suiteNo.trim().length === 0) {
            this.isError = true;
            this.errors.suiteNo = this.errMsgRequired;
        }*/
        if (this.event.city.trim().length === 0) {
            this.isError = true;
            this.errors.city = this.errMsgRequired;
        }
        if (this.event.stateID === 0) {
            this.isError = true;
            this.errors.state = this.errMsgRequired;
        }
        if (this.event.zipCode.trim().length === 0) {
            this.isError = true;
            this.errors.zipCode = this.errMsgRequired;
        } else if (!this.ZIP_REGEX.test(this.event.zipCode.trim())) {
            this.isError = true;
            this.errors.zipCode = 'Should contain only valid us zipcode';
        }
        if (this.event.multiDate === true) {
            if (mErrorMsg === '1') {
                this.isError = true;
                this.errors.noOfDays = 'Please provide start and end date and time';
            } else {
                this.isError = false;
                this.errors.noOfDays = '';
            }
            if (this.event.noOfDays.trim().length === 0) {
                this.isError = true;
                this.errors.noOfDays = 'please provide no of days';
            } else if (!this.NUM_REGEX.test(this.event.noOfDays.trim())) {
                this.isError = true;
                this.errors.noOfDays = 'please provide valid input';
            } else {
                for (let i = 0; i < this.event.multiDateArr.length; i++) {
                      this.event.multiDateArr[i].endDt =this.event.multiDateArr[i].startDt; 

                     if ((
                        (moment(this.event.multiDateArr[i].startDt).format('YYYY/MM/DD') ===
                            moment(this.event.multiDateArr[i].endDt).format('YYYY/MM/DD')) &&
                        (moment(this.event.multiDateArr[i].startTime).format('hh:mm') >
                            moment(this.event.multiDateArr[i].endTime).format('hh:mm')))) {
                        this.isError = true;
                        this.errors.noOfDays = 'Start time should be less than end time since dates are same';
                    }
                }
                this.event.eventStartDate = this.event.eventEndDate = this.event.eventStartTime = this.event.eventEndTime = '';
            }
        }
       

        if (this.event.multiDate === false) {
            if (this.event.eventStartDate.length === 0) {
                this.isError = true;
                this.errors.eventStartDate = this.errMsgRequired;
            }
             else  if(this.event.eventEndDate.length !== 0){
                if (moment(this.event.eventStartDate).format('YYYY/MM/DD') > moment(this.event.eventEndDate).format('YYYY/MM/DD')) {
                    this.isError = true;
                    this.errors.eventStartDate = this.errMsgDate;
                } else if (
                    ((moment(this.event.eventStartDate).format('YYYY/MM/DD') === moment(this.event.eventEndDate).format('YYYY/MM/DD'))
                        && (moment(this.event.eventStartTime).format('hh:mm') > moment(this.event.eventEndTime).format('hh:mm')))) {
                    this.isError = true;
                    this.errors.eventStartDate = 'Event start time should greate than end time since Both dates are same';
                } else {
                    this.errors.eventStartDate = this.errors.eventEndDate = this.errors.eventStartTime = this.errors.eventEndTime = '';
                }
            }
        }
        if (this.event.scheduleGroupTypeID.toString()  == '21' ) {
            if(this.event.privateCode == '') {
                 this.isError = true;
                  this.errors.privateCode = this.errMsgRequired;

              } 
        } 
     
        if(this.event.ticketStartDate.length > 0 
            && this.event.ticketEndDate.length > 0){

        if (this.event.ticketStartDate > this.event.ticketEndDate) {
            this.isError = true;
            this.errors.ticketStartDate = this.errMsgDate;
        } else if (this.event.ticketStartDate === this.event.ticketEndDate) {
            if (this.event.eventStartTime > this.event.eventEndTime) {
                this.isError = true;
                this.errors.eventStartDate = 'Event start time should greate than end time since Both dates are same';
            }
        } /*else {
            this.errors.ticketEndTime = this.errors.ticketStartTime = this.errors.ticketStartDate = this.errors.ticketEndDate = '';
        }*/
    }
        if (this.event.eventWebLink.trim().length > 0 && this.checkLinkFlag === false) {
            this.isError = true;
            this.errors.eventWebLink = "Please provide valid url";
        }
        if (this.event.eventDescription.trim().length === 0) {
            this.isError = true;
            this.errors.eventDescription = this.errMsgRequired;
        }
        return this.isError;
    }

    checkUrl(){

       if(this.event.eventWebLink.length ===0) {
         this.isError = true;
         this.errors.eventWebLink = "Please provide web link";

       } else {

               const url = "http://" + this.event.eventWebLink;

                this.httpService.checkWebLink(url)
                .subscribe((resp: any) => { console.log(resp);
                    this.reqInProgress = false;
                    if (resp.responseCode === 1) {
                    this.errors.eventWebLink = (resp.data[0] == true) ? "Url is valid " : "Url is not valid" ;
                     if(resp.data[0] == false) {
                     this.isError =true;
                     this.checkLinkFlag = false; 
                     } else {
                         this.checkLinkFlag = true;

                      }
                    

                     
                       

                    } 
                }, (errorResponse: HttpErrorResponse) => {
                  
                 
                    this.isError = true;
                    this.errorMsg = errorResponse.error ? errorResponse.error.message
                        : 'Sorry, unable to complete your request, please refresh this page and try again';
                });
        
      

       }
       return this.isError;
       
          
      
   
          
    }

    submitEvent() {
        this.errorMsg = '';
        if (!this.doValidation() && !this.reqInProgress) {
          //  const sId = parseInt(this.event.stateID, 10);
          var sId = this.event.stateID;
            let sName = '';
            this.states.forEach(function (state) {
                if (state.stateID === sId) {
                    sName = state.stateName;
                }
            });
            this.event.state = sName;

            if (this.event.multiDate === true) {
                for (let i = 0; i < this.event.multiDateArr.length; i++) {
                    this.event.EventDays[i] = {
                    StartDate:moment( this.event.multiDateArr[i].startDt).format('YYYY/MM/DD'),
                    StartTime: moment(this.event.multiDateArr[i].startTime).format('hh:mm'),
                    EndDate:  moment( this.event.multiDateArr[i].endDt).format('YYYY/MM/DD'),
                    EndTime: moment( this.event.multiDateArr[i].endTime).format('hh:mm')
                 };
                }
                const lastnode = this.event.EventDays.length -1;
                this.event.eventStartDate =  this.event.EventDays[0].StartDate;
                this.event.eventStartTime = this.event.EventDays[0].StartTime;
                this.event.eventEndDate = this.event.EventDays[lastnode].EndDate;
                this.event.eventEndTime = this.event.EventDays[lastnode].EndTime;

            } else {
               
                const d1 = moment(this.event.eventStartDate).format('YYYY/MM/DD');
                this.event.eventStartDate = d1;
                const t1 = moment(this.event.eventStartTime).format('hh:mm');
                this.event.eventStartTime = t1;
                if(this.event.eventEndDate !== ''){
                const d2 = moment(this.event.eventEndDate).format('YYYY/MM/DD');
                this.event.eventEndDate = d2;
                } else {
                    this.event.eventEndDate = d1;

                }

                if(this.event.eventEndTime !== ''){
                const t2 = moment(this.event.eventEndTime).format('hh:mm');
                this.event.eventEndTime = t2;
                } else {
                    this.event.eventEndTime = t1;

                }
                if(this.event.ticketStartDate !== ''){

                const d3 = moment(this.event.ticketStartDate).format('YYYY/MM/DD');
                this.event.ticketStartDate = d3;
                }

                if(this.event.ticketStartTime !== ''){

                const t3 = moment(this.event.ticketStartTime).format('hh:mm');
                this.event.ticketStartTime = t3;

                }
                if(this.event.ticketEndDate !== ''){

                const d4 = moment(this.event.ticketEndDate).format('YYYY/MM/DD');
                this.event.ticketEndDate = d4;
                }

                if(this.event.ticketEndTime !== ''){

                const t4 = moment(this.event.ticketEndTime).format('hh:mm');
                this.event.ticketEndTime = t4;
                }
            }

            this.reqInProgress = true;
            this.submitBtnTxt = 'Please wait...';
           this.httpService.createEvent(this.event)
                .subscribe((resp: any) => {
                    this.reqInProgress = false;
                    this.submitBtnTxt = 'Save';
                    if (resp.responseCode === 1) {
                        setTimeout(() => {
                            this.successMsg = resp.message;
                            window.scrollTo(0, 0);
                            setTimeout(() => {
                                this.router.navigate([`/invite/event/${resp.data[0].eventID}`]);
                            }, 2000);
                        }, 2000);
                   // this.router.navigate([`/invite/event/${resp.data[0].eventID}`]);

                    } else {
                        this.isError = true;
                        this.errorMsg = resp.message;
                    

                    }
                }, (errorResponse: HttpErrorResponse) => {
                    console.log(errorResponse);
                    this.reqInProgress = false; 
                    
                    this.submitBtnTxt = 'Save';
                    this.isError = true;
                    this.errorMsg = errorResponse.error ? errorResponse.error.message
                        : 'Sorry, unable to complete your request, please refresh this page and try again';
                });
               // this.event.scheduleGroupTypeID = this.scheduleGroup;
                      this.event.eventStartDate = this.day1 ;
                      this.event.eventStartTime  = this.time1 ;
        }
    }

    ngOnInit() {

        this.route.params
        .subscribe(params => {
            this.eventId = params['id'];
        });
        if( this.eventId > 0){
            this.event.eventId = this.eventId;
            this.httpService.getEventDetails(this.eventId).subscribe((resp: any) => {
                if (resp.responseCode === 1) {
                    this.constructEditObj(resp.data);
                }
            });
        } /*else {
            this.httpService.getPrivateCode().subscribe((resp: any) => {
                if (resp.responseCode === 1) {
                    this.privateCode = resp.data.privateCode;
                }
            });

        }*/
        this.event.userId = `${this.userService.getLoginUserId()}`;
        this.httpService.getStates(this.country.id).subscribe((resp: any) => {
            if (resp.responseCode === 1) {
                this.states = resp.data;
            }
        });
        this.httpService.getScheduleGroups().subscribe((resp: any) => {
            if (resp.responseCode === 1) {
                this.scheduleGroups = resp.data;
            }
        });

       
        this.httpService.getScheduleCategories().subscribe((resp: any) => {
            if (resp.responseCode === 1) {
                this.scheduleCategories = resp.data;
            }
        });
    }
}
