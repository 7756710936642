import {Component, OnInit} from '@angular/core';
import {HttpService} from '../services/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { EncodeURIPipe } from 'angular-pipes';
import {UserService} from '../services/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    parentName = 'home';
    isLoggedIn:boolean;
    loginObj:any;
    events: any[];
    geolocation:any;
    geolocationCount= 0 ;
    isGeofound = false;
    component = '' ;
    joincode = '' ;
    states:any[];
    concatString:any;
    scheduleCategories:any;
    popular = false;
    ready = false;
    results : any ;
    isError = false;
   reqInProgress = false;
   submitBtnTxt = 'Search';
   errorMsg = '';
   successMsg = '';
   


   country = {
    id: 1,
    code: 'US',
    name: 'UNITED STATES'
};
    search = {

        scheduleCategoryTypeID: 0,
        countryId: 1 ,
        scheduleTypeId: '0' ,
        scheduleName : '' ,
        fromDate : '' ,
        toDate : '' ,
        location : '' ,
        listType : 0 ,
        userId: 0,
      };

    constructor(private route: ActivatedRoute,private httpService: HttpService) {
    }
 
    updateLikes(cardObj) {
        const index = this.events.findIndex((obj => obj.scheduleID === cardObj.scheduleID));
        this.events[index].liked = true;
    }

    callsearch(flag) {
        this.httpService.searchEvent(this.search)
        .subscribe((resp: any) => {
          this.reqInProgress = false;
          this.submitBtnTxt = 'Save';
          
          if (resp.body != null && resp.body.responseCode === 1) {
            this.events = resp.body.data; 
            if(flag == 1){ 
            this.geolocationCount = this.events.length;
        this.popular = (this.geolocationCount > 0) ? true : false;
            } else {
                this.popular = false;
            }
          //  
            this.search.fromDate ='';
            this.search.toDate = '';
            /*  setTimeout(() => {
                  this.successMsg = resp.message;
                //  window.scrollTo(0, 0);
                  setTimeout(() =>  {
                     // this.router.navigate([`/invite/conference/${resp.data[0].conferenceID}`]);
                  }, 2000);
              }, 2000);*/
    
          } else {
              this.isError = true;
              this.events= [];
              this.events.length =0;
              this.errorMsg = resp.message;
              this.search.fromDate ='';
              this.search.toDate = '';
          }
      }, (errorResponse: HttpErrorResponse) => {
         // console.log(errorResponse);
          this.reqInProgress = false;
          this.submitBtnTxt = 'Save';
          this.events= [];
          this.events.length =0;
          this.search.fromDate ='';
          this.search.toDate = '';
          this.isError = true;
          this.errorMsg = errorResponse.error ? errorResponse.error.message
              : 'Sorry, unable to complete your request, please refresh this page and try again';
      });
      }
      
      isDataEmpty() {
        return !(this.events.length > 0);
    }
      submitfrm(){
       
        this.search.fromDate = (this.search.fromDate !== '') ? moment(this.search.fromDate).format('YYYY/MM/DD') : '';
        this.search.toDate = (this.search.toDate !== '') ? moment(this.search.toDate).format('YYYY/MM/DD') : '';
        if(this.search.scheduleName!='' || this.search.fromDate!='' || this.search.location!=''){
        this.callsearch(0);
        } else {
            this.isError =true;
            this.errorMsg = "please choose any one of field event,from date or city";

        }
      }
      

    ngOnInit() {

     
       this.isLoggedIn = (localStorage.getItem('userInfo')!==null) ? true :false ;
     
       this.route.params
        .subscribe(params => {
            this.component = params['component'];
            this.joincode = params['actcode'];
        });
 

        this.httpService.getStates(this.country.id).subscribe((resp: any) => {
            if (resp.responseCode === 1) {
                this.states = resp.data;
            }
        });
        
        setTimeout(() => {
           this.httpService.googleLink().subscribe((resp:any)=>{
            this.geolocation = resp;
            this.concatString = this.geolocation.city+ ' ,' + this.geolocation.region;
            let sname ='';
           // console.log(this.states.length);
            for (let i = 0; i < this.states.length; i++) {
                 if(this.states[i].stateName.toLowerCase() == this.geolocation.region.toLowerCase()){
                     this.search.location = this.geolocation.city;
                     this.isGeofound = true ;
                     
                     this.callsearch(1);
                     this.ready = true;
                 } 

                }
            });
        },500);

            this.httpService.getScheduleCategories().subscribe((resp: any) => {
                if (resp.responseCode === 1) {
                    this.scheduleCategories = resp.data;
                }
            });

            if(this.search.location.length === 0 && this.geolocationCount === 0){
                    
            this.httpService.getEventsListHomePg().subscribe((resp: any) => {
                        this.ready = true;
                        let tmpList: any[];
                        if (resp.responseCode === 1) {
                            tmpList = resp.data;
                            }
                        if (tmpList.length > 6) {
                            this.events = tmpList.slice(-7, -1);
                        } else {
                            this.events = tmpList;
                        }
                        const tmpData = (this.events) || [];
                        const tmpDataNew = [];
                        tmpData.forEach(function (v) {
                            const tmp = v;
                            tmp.isError = false;
                            tmp.errorMsg = '';
                            tmp.reqInProgress = false;
                            tmp.buttonText = 'Delete';
                            tmp.deleteReason = '';
                            tmpDataNew.push(tmp);
                        });
                        this.events = [];
                        this.events.push(...tmpDataNew);
                    }, (err: HttpErrorResponse) => {
                        this.ready = true;
                        console.error(err);
                    });
                    
                }
                

           
     

    }

}
